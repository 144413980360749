import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button, Card, Table } from 'react-bootstrap'
import { getAccessToken } from '../../../../../../services/auth.service'
import { AddLineModal } from './lines/AddLineModal' // Adjust path as needed
import { EditLineModal } from './lines/EditLineModal' // Adjust path as needed
import { AddLinekeyModal } from './linekeys/AddLinekeyModal' // Adjust path as needed
import { EditLinekeyModal } from './linekeys/EditLinekeyModal' // Adjust path as needed
import { lineKeyTypeTranslations } from './linekeys/lineKeyTypes' // Adjust path as needed

interface VoipToestelApiResponse {
  id: string
  customer: {
    id: number
    name: string
  }
  model: {
    id: string
    vendor: string
    name: string
  }
  lines: {
    index: number
    id: number
    extension: number
    label: string
  }[]
  keys: {
    index: number
    line: number
    type: number
    value: string
    label: string
  }[]
  keyTemplate: {
    id: string | null
    name: string | null
    vars: {
      index: number
      line: number
      type: number
      value: string
      label: string
    }[] | null
  }
  firmware: {
    id: string | null
    version: string | null
  }
}

export function VoipToestel() {
  const { accountid, macaddress } = useParams()
  const [voipToestel, setVoipToestel] = React.useState<VoipToestelApiResponse | null>(null)
  const [showAddLineModal, setShowAddLineModal] = React.useState<boolean>(false)
  const [showEditLineModal, setShowEditLineModal] = React.useState<boolean>(false)
  const [editingLine, setEditingLine] = React.useState<{
    recordId: number
    initialLine: number
    initialVoipLine: number
  } | null>(null)
  const [showAddLinekeyModal, setShowAddLinekeyModal] = React.useState<boolean>(false)
  const [showEditLinekeyModal, setShowEditLinekeyModal] = React.useState<boolean>(false)
  const [editingLineKey, setEditingLineKey] = React.useState<{
    index: number
    line: number
    type: number
    value: string
    label: string
  } | null>(null)

  const voipToestelQuery = useQuery({
    queryKey: ['voipToestel', macaddress],
    queryFn: async () => {
      const response = await fetch(`/api/voip/toestellen/${accountid}/${macaddress}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const json = await response.json()
      return json as VoipToestelApiResponse
    },
  })

  // Update state when new data is fetched.
  React.useEffect(() => {
    if (voipToestelQuery.data) {
      setVoipToestel(voipToestelQuery.data)
    }
  }, [voipToestelQuery.data])

  // Calculate next available line and key indexes.
  const nextLineId
    = voipToestel && voipToestel.lines.length > 0
      ? Math.max(...voipToestel.lines.map(l => l.index)) + 1
      : 1

  const nextKeyIndex
    = voipToestel && voipToestel.keys.length > 0
      ? Math.max(...voipToestel.keys.map(k => k.index)) + 1
      : 1

  // Handler for editing a line.
  const handleEditLineClick = (line: { index: number, id: number, extension: number, label: string }) => {
    // Use line.index as record id and initial line value, and line.id as the initially assigned VoIP account.
    setEditingLine({
      recordId: line.index,
      initialLine: line.index,
      initialVoipLine: line.id,
    })
    setShowEditLineModal(true)
  }

  // Handler for editing a key.
  const handleEditLineKeyClick = (key: { index: number, line: number, type: number, value: string, label: string }) => {
    setEditingLineKey(key)
    setShowEditLinekeyModal(true)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <h5 className="card-title">VoIP toestel</h5>
        </Card.Header>
        <Card.Body>
          {voipToestel
            ? (
                <div>
                  <p>
                    <strong>Account ID:</strong>
                    {' '}
                    {voipToestel.customer.id}
                  </p>
                  <p>
                    <strong>Account name:</strong>
                    {' '}
                    {voipToestel.customer.name}
                  </p>
                  <p>
                    <strong>Model:</strong>
                    {' '}
                    {voipToestel.model.vendor}
                    {' '}
                    {voipToestel.model.name}
                  </p>
                  <p>
                    <strong>Firmware:</strong>
                    {' '}
                    {voipToestel.firmware.version}
                  </p>
                </div>
              )
            : (
                <div className="alert alert-info" role="alert">
                  Loading...
                </div>
              )}

          {/* Lines Card */}
          <Card>
            <Card.Header>
              <h2 className="card-title mb-0">Lijnen</h2>
              <div className="card-tools">
                <Button variant="primary" onClick={() => setShowAddLineModal(true)}>
                  Add Line
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Lijn</th>
                    <th>Extensie</th>
                    <th>Account</th>
                    <th>Label</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {voipToestel?.lines.map(line => (
                    <tr key={line.index}>
                      <td>{line.index}</td>
                      <td>{line.extension}</td>
                      <td>{line.id}</td>
                      <td>{line.label}</td>
                      <td>
                        <Button variant="secondary" size="sm" onClick={() => handleEditLineClick(line)}>
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          {/* Keys Card */}
          <Card className="mt-3">
            <Card.Header>
              <h5 className="card-title mb-0">Keys</h5>
              <div className="card-tools">
                <Button variant="primary" onClick={() => setShowAddLinekeyModal(true)}>
                  Add Key
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Lijn</th>
                    <th>Type</th>
                    <th>Value</th>
                    <th>Label</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {voipToestel?.keys.map(key => (
                    <tr key={key.index}>
                      <td>{key.index}</td>
                      <td>{key.line}</td>
                      <td>{lineKeyTypeTranslations[key.type]}</td>
                      <td>{key.value}</td>
                      <td>{key.label}</td>
                      <td>
                        <Button variant="secondary" size="sm" onClick={() => handleEditLineKeyClick(key)}>
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>

      {/* AddLineModal */}
      {voipToestel && (
        <AddLineModal
          show={showAddLineModal}
          onHide={() => setShowAddLineModal(false)}
          toestelId={voipToestel.id}
          voipCustomer={voipToestel.customer}
          onLineAdded={() => voipToestelQuery.refetch()}
          initialLine={nextLineId}
        />
      )}

      {/* EditLineModal */}
      {voipToestel && editingLine && (
        <EditLineModal
          show={showEditLineModal}
          onHide={() => setShowEditLineModal(false)}
          toestelId={voipToestel.id}
          voipCustomer={voipToestel.customer}
          lineRecordId={editingLine.recordId}
          initialVoipLine={editingLine.initialVoipLine}
          onLineEdited={() => voipToestelQuery.refetch()}
        />
      )}

      {/* AddLinekeyModal */}
      {voipToestel && (
        <AddLinekeyModal
          show={showAddLinekeyModal}
          onHide={() => setShowAddLinekeyModal(false)}
          toestelId={voipToestel.id}
          macaddress={macaddress!}
          initialIndex={nextKeyIndex}
          availableLines={voipToestel.lines}
          voipCustomer={voipToestel.customer}
          onLinekeyAdded={() => voipToestelQuery.refetch()}
        />
      )}

      {/* EditLinekeyModal */}
      {voipToestel && editingLineKey && (
        <EditLinekeyModal
          show={showEditLinekeyModal}
          onHide={() => setShowEditLinekeyModal(false)}
          toestelId={voipToestel.id}
          macaddress={macaddress!}
          initialIndex={editingLineKey.index}
          initialLine={editingLineKey.line}
          initialType={editingLineKey.type}
          initialValue={editingLineKey.value}
          initialLabel={editingLineKey.label}
          availableLines={voipToestel.lines}
          voipCustomer={voipToestel.customer}
          onLinekeyEdited={() => voipToestelQuery.refetch()}
        />
      )}
    </>
  )
}
