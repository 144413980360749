// EditLineModal.tsx
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { useMutation, useQuery } from '@tanstack/react-query'
import Select from 'react-select'
import { getAccessToken } from '../../../../../../../services/auth.service'
import { selectTheme } from '../../../../../../../util/helpers'
import { DarkModeContext } from '../../../../../../../providers/DarkModeProvider'

interface VoipLineAccount {
  id: number
  extension: number
  label: string
}

interface Option {
  value: number
  label: string
}

interface EditLineModalProps {
  show: boolean
  onHide: () => void
  toestelId: string // For endpoint URL
  voipCustomer: { id: number } | null
  lineRecordId: number // Immutable line number to display
  initialVoipLine: number // The currently assigned VoIP account id
  onLineEdited?: () => void
}

export const EditLineModal: React.FC<EditLineModalProps> = ({
  show,
  onHide,
  toestelId,
  voipCustomer,
  lineRecordId,
  initialVoipLine,
  onLineEdited,
}) => {
  // The immutable line number is derived from lineRecordId.
  const lineNumber = lineRecordId

  const [voipLine, setVoipLine] = useState<Option | null>(null)
  const [formError, setFormError] = useState<string | null>(null)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const darkMode = useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  // Fetch available VoIP accounts.
  const { data: accountsData, isLoading: accountsLoading, error: accountsError } = useQuery<VoipLineAccount[]>({
    queryKey: ['voipPhones', voipCustomer?.id, 'accounts'],
    enabled: voipCustomer !== null,
    queryFn: async () => {
      const response = await fetch(`/api/voip/klanten/${voipCustomer?.id}/accounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  // Memoize the accounts options.
  const accountsOptions = useMemo(() => {
    return accountsData
      ? accountsData.map(account => ({
        value: account.id,
        label: `${account.extension} - ${account.label}`,
      }))
      : []
  }, [accountsData])

  // Initialize the VoIP account selection when the modal opens.
  useEffect(() => {
    if (show) {
      const found = accountsOptions.find(opt => opt.value === initialVoipLine)
      setVoipLine(found || (accountsOptions.length > 0 ? accountsOptions[0] : null))
      setFormError(null)
    }
  }, [show, initialVoipLine, accountsOptions])

  // Mutation for updating the line (only the VoIP account) using PATCH.
  const updateMutation = useMutation({
    mutationFn: async (data: { voipLine: number }) => {
      const response = await fetch(`/api/voip/toestellen/${toestelId}/lines/${lineRecordId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
      })
      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(errorText || 'Error updating line')
      }
      return response.json()
    },
  })

  // Mutation for deleting the line.
  const deleteMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(`/api/voip/toestellen/${toestelId}/lines/${lineRecordId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(errorText || 'Error deleting line')
      }
      return response.json()
    },
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormError(null)
    if (!voipLine) {
      setFormError('Please select a VoIP account.')
      return
    }
    updateMutation.mutate(
      { voipLine: voipLine.value },
      {
        onSuccess: () => {
          onLineEdited && onLineEdited()
          onHide()
        },
        onError: (error: any) => {
          setFormError(error.message || 'Failed to update line.')
        },
      },
    )
  }

  const handleDelete = () => {
    setShowConfirmDelete(true)
  }

  const handleClose = () => {
    setFormError(null)
    onHide()
  }

  const isMutating = updateMutation.status === 'pending' || deleteMutation.status === 'pending'

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Edit Line
              &nbsp;#
              {lineNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {formError && <Alert variant="danger">{formError}</Alert>}
            <Form.Group controlId="voipLineSelect" className="mb-3">
              <Form.Label>VoIP Account</Form.Label>
              {accountsLoading
                ? (
                    <div>Loading accounts...</div>
                  )
                : accountsError
                  ? (
                      <Alert variant="danger">Error loading accounts.</Alert>
                    )
                  : (
                      <Select
                        theme={selectStyle}
                        options={accountsOptions}
                        value={voipLine}
                        onChange={selectedOption => setVoipLine(selectedOption as Option)}
                        placeholder="Select a VoIP account"
                      />
                    )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div>
              <Button variant="danger" onClick={handleDelete} disabled={isMutating} className="me-2">
                Delete
              </Button>
              <Button variant="secondary" onClick={handleClose} disabled={isMutating}>
                Cancel
              </Button>
            </div>
            <Button variant="primary" type="submit" disabled={isMutating}>
              {updateMutation.status === 'pending' ? 'Saving...' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* In-theme Delete Confirmation Modal */}
      {showConfirmDelete && (
        <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this line?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                deleteMutation.mutate(undefined, {
                  onSuccess: () => {
                    onLineEdited && onLineEdited()
                    setShowConfirmDelete(false)
                    onHide()
                  },
                  onError: (error: any) => {
                    setFormError(error.message || 'Failed to delete line.')
                  },
                })}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
