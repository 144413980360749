import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getAccessToken } from '../../../services/auth.service'
import { OutageImpact, OutageSeverity, OutageStatus, OutageType } from './Types'

export interface NewOutageModalProps {
  show: boolean
  handleClose: () => void
}

export function NewOutageModal({
  show,
  handleClose,
}: NewOutageModalProps) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [impact, setImpact] = useState<OutageImpact>(OutageImpact.LOCAL)
  const [severity, setSeverity] = useState<OutageSeverity>(OutageSeverity.LOW)
  const [type, setType] = useState<OutageType>(OutageType.UNSCHEDULED)
  const [status, setStatus] = useState<OutageStatus>(OutageStatus.ACTIVE)
  const [start, setStart] = useState<Date>(new Date())

  const disabled = !title || !description || !impact || !severity || !type || !status || !start

  const queryClient = useQueryClient()

  const handleCreate = async () => {
    const response = await fetch('/api/notifications/outages', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title,
        description,
        impact,
        severity,
        type,
        status,
        start,
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het aanmaken van de storing')
      throw new Error(response.statusText)
    }

    toast.success('Storing aangemaakt')

    setTitle('')
    setDescription('')
    setImpact(OutageImpact.LOCAL)
    setSeverity(OutageSeverity.LOW)
    setType(OutageType.UNSCHEDULED)
    setStatus(OutageStatus.ACTIVE)
    setStart(new Date())

    queryClient.invalidateQueries({
      queryKey: ['outages'],
    })

    handleClose()
  }

  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>Nieuwe storing</Modal.Title>
          <button type="button" className="close" onClick={handleClose}>
            <span>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">Titel</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Beschrijving</label>
            <textarea
              className="form-control"
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="status" className="form-label">Status</label>
            <select
              className="form-select"
              id="status"
              value={status}
              onChange={e => setStatus(e.target.value as OutageStatus)}
            >
              <option value={OutageStatus.ACTIVE}>Actief</option>
              <option value={OutageStatus.PLANNED}>Gepland</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="type" className="form-label">Type</label>
            <select
              className="form-select"
              id="type"
              value={type}
              onChange={e => setType(e.target.value as OutageType)}
            >
              <option value={OutageType.UNSCHEDULED}>Ongepland</option>
              <option value={OutageType.SCHEDULED}>Gepland</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="impact" className="form-label">Impact</label>
            <select
              className="form-select"
              id="impact"
              value={impact}
              onChange={e => setImpact(e.target.value as OutageImpact)}
            >
              <option value={OutageImpact.LOCAL}>Lokaal</option>
              <option value={OutageImpact.REGIONAL}>Regionaal</option>
              <option value={OutageImpact.NATIONAL}>Nationaal</option>
              <option value={OutageImpact.GLOBAL}>Globaal</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="severity" className="form-label">Impact</label>
            <select
              className="form-select"
              id="severity"
              value={severity}
              onChange={e => setSeverity(e.target.value as OutageSeverity)}
            >
              <option value={OutageSeverity.LOW}>Laag</option>
              <option value={OutageSeverity.MEDIUM}>Gemiddeld</option>
              <option value={OutageSeverity.HIGH}>Hoog</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="start" className="form-label">Start</label>
            <input
              type="datetime-local"
              className="form-control"
              id="start"
              value={start.toISOString().substring(0, 16)}
              onChange={e => setStart(new Date(e.target.value))}
            />
          </div>

          <button type="button" className="btn btn-secondary" onClick={handleClose}>Annuleer</button>
          <button type="button" className="btn btn-primary" onClick={handleCreate} disabled={disabled}>Maak storing</button>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  )
}
