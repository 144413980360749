// lineKeyTypes.ts

export enum LineKeyTypes {
  NA = 0,
  Conference = 1,
  Forward = 2,
  Transfer = 3,
  Hold = 4,
  DND = 5,
  CallReturn = 7,
  SMS = 8,
  DirectedPickup = 9,
  CallPark = 10,
  DTMF = 11,
  VoiceMail = 12,
  SpeedDial = 13,
  Intercom = 14,
  Line = 15,
  BLF = 16,
  URL = 17,
  GroupListening = 18,
  PrivateHold = 20,
  XMLGroup = 22,
  GroupPickup = 23,
  MulticastPaging = 24,
  Record = 25,
  XMLBrowser = 27,
  HotDesking = 34,
  URLRecord = 35,
  LDAP = 38,
  BLFList = 39,
  Prefix = 40,
  ZeroTouch = 41,
  ACD = 42,
  LocalGroup = 45,
  NetworkGroup = 46,
  CustomButton = 49,
  KeypadLock = 50,
  MeetMeConference = 55,
  RetrievePark = 56,
  Hoteling = 57,
  ACDGrace = 58,
  SispCode = 59,
  Emergency = 60,
  Directory = 61,
}

export const lineKeyTypeTranslations: Record<number, string> = {
  [LineKeyTypes.NA]: 'NA',
  [LineKeyTypes.Conference]: 'Conference',
  [LineKeyTypes.Forward]: 'Forward',
  [LineKeyTypes.Transfer]: 'Transfer',
  [LineKeyTypes.Hold]: 'Hold',
  [LineKeyTypes.DND]: 'DND',
  [LineKeyTypes.CallReturn]: 'CallReturn',
  [LineKeyTypes.SMS]: 'SMS',
  [LineKeyTypes.DirectedPickup]: 'DirectedPickup',
  [LineKeyTypes.CallPark]: 'CallPark',
  [LineKeyTypes.DTMF]: 'DTMF',
  [LineKeyTypes.VoiceMail]: 'VoiceMail',
  [LineKeyTypes.SpeedDial]: 'SpeedDial',
  [LineKeyTypes.Intercom]: 'Intercom',
  [LineKeyTypes.Line]: 'Line',
  [LineKeyTypes.BLF]: 'BLF',
  [LineKeyTypes.URL]: 'URL',
  [LineKeyTypes.GroupListening]: 'GroupListening',
  [LineKeyTypes.PrivateHold]: 'PrivateHold',
  [LineKeyTypes.XMLGroup]: 'XMLGroup',
  [LineKeyTypes.GroupPickup]: 'GroupPickup',
  [LineKeyTypes.MulticastPaging]: 'MulticastPaging',
  [LineKeyTypes.Record]: 'Record',
  [LineKeyTypes.XMLBrowser]: 'XMLBrowser',
  [LineKeyTypes.HotDesking]: 'HotDesking',
  [LineKeyTypes.URLRecord]: 'URLRecord',
  [LineKeyTypes.LDAP]: 'LDAP',
  [LineKeyTypes.BLFList]: 'BLFList',
  [LineKeyTypes.Prefix]: 'Prefix',
  [LineKeyTypes.ZeroTouch]: 'ZeroTouch',
  [LineKeyTypes.ACD]: 'ACD',
  [LineKeyTypes.LocalGroup]: 'LocalGroup',
  [LineKeyTypes.NetworkGroup]: 'NetworkGroup',
  [LineKeyTypes.CustomButton]: 'CustomButton',
  [LineKeyTypes.KeypadLock]: 'KeypadLock',
  [LineKeyTypes.MeetMeConference]: 'MeetMeConference',
  [LineKeyTypes.RetrievePark]: 'RetrievePark',
  [LineKeyTypes.Hoteling]: 'Hoteling',
  [LineKeyTypes.ACDGrace]: 'ACDGrace',
  [LineKeyTypes.SispCode]: 'SispCode',
  [LineKeyTypes.Emergency]: 'Emergency',
  [LineKeyTypes.Directory]: 'Directory',
}
