import { Line } from 'react-chartjs-2'
import type { ChartOptions } from 'chart.js'
import { Chart, Filler, Legend, LineElement, LinearScale, PointElement, TimeScale, Tooltip } from 'chart.js'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import { getAccessToken } from '../../../../services/auth.service'

Chart.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, LineElement, Filler)

interface GraphData {
  clock: number
  value: number
}

export function InterconnectBandwidthCard({ id }: { id: string }) {
  const [uploadData, setUploadData] = useState<GraphData[]>([{ clock: Math.floor(Date.now() / 1000), value: 0 }])
  const [downloadData, setDownloadData] = useState<GraphData[]>([{ clock: Math.floor(Date.now() / 1000), value: 0 }])

  const darkMode = useContext(DarkModeContext).isDarkMode

  const interconnectGraphQuery = useQuery({
    queryKey: ['interconnect', 'graph', id],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/interconnects/${id}/bandwidthGraphData`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      if (json.upload.length === 0 || json.download.length === 0) {
        throw new Error('No data')
      }

      // Also throw error if length is 1
      if (json.upload.length === 1 || json.download.length === 1) {
        throw new Error('Not enough data')
      }

      return json
    },
    refetchInterval: 1000 * 60 * 3,
  })

  useEffect(() => {
    if (interconnectGraphQuery && interconnectGraphQuery.data) {
      setUploadData(interconnectGraphQuery.data.upload)
      setDownloadData(interconnectGraphQuery.data.download)
    }
  }, [interconnectGraphQuery.data])

  // Get largest value
  const largestValue = Math.max(
    ...uploadData.map(data => data.value),
    ...downloadData.map(data => data.value),
  )

  let divideBy = 1
  let speedLabel = 'bps'

  // If the largest value in bps is larger than 3kbps convert to kbps
  if (largestValue > 2000) {
    divideBy = 1000
    speedLabel = 'kbps'
  }

  // If the largest value in kbps is larger than 3mbit convert to mbit
  if (largestValue / divideBy > 3000) {
    divideBy = 1000000
    speedLabel = 'mbit'
  }

  // Prepare data for the chart
  const uploadDataPoints = uploadData.map(data => ({
    x: new Date(data.clock * 1000),
    y: data.value / divideBy,
  }))

  const downloadDataPoints = downloadData.map(data => ({
    x: new Date(data.clock * 1000),
    y: data.value / divideBy,
  }))

  const chartData = {
    datasets: [
      {
        label: 'Upload',
        data: uploadDataPoints,
        borderColor: 'rgba(233, 82, 27, 1)',
        borderWidth: 1,
        pointRadius: 0, // Remove the points
        pointHoverRadius: 0, // Remove the points on hover
        backgroundColor: 'rgba(233, 82, 27, 0.2)',
        fill: true,
      },
      {
        label: 'Download',
        data: downloadDataPoints,
        borderColor: 'rgba(28, 133, 199, 1)',
        borderWidth: 1,
        pointRadius: 0, // Remove the points
        pointHoverRadius: 0, // Remove the points on hover
        fill: 'origin',
        backgroundColor: 'rgba(28, 133, 199, 0.2)',
      },
    ] as {
      label: string
      data: { x: Date, y: number }[]
      borderColor: string
      borderWidth: number
      pointRadius: number
      pointHoverRadius: number
      fill: boolean | 'origin'
      backgroundColor: string
      hidden?: boolean
    }[],
  }

  let minChartHeight = 2000000
  if (speedLabel === 'kbps') {
    minChartHeight = 2000
  }
  else if (speedLabel === 'mbit') {
    minChartHeight = 2
  }

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          color: darkMode ? 'white' : 'black',
        },
        ticks: {
          color: darkMode ? 'white' : 'black',
          callback: (value) => {
            const date = new Date(value)
            const dateString = date.toLocaleDateString()
            const timeString = date.toLocaleTimeString()
            return [dateString, timeString]
          },
        },
        min: downloadData[0].clock * 1000,
        // Maximum value - 48 hours
        // min: downloadData[downloadData.length - 1].clock * 1000 - (24 * 60 * 60 * 1000),
        max: downloadData[downloadData.length - 1].clock * 1000,
      },
      y: {
        title: {
          display: true,
          text: `Bandbreedte (${speedLabel})`, // Update the Y-axis label
          color: darkMode ? 'white' : 'black',
        },
        ticks: {
          color: darkMode ? 'white' : 'black',
        },
        max: largestValue / divideBy >= minChartHeight ? undefined : minChartHeight,
      },
    },
    plugins: {
      tooltip: {
        enabled: true, // Show tooltips on hover
        intersect: false,
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || ''
            if (label) {
              label += ': '
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y.toFixed(2)} ${speedLabel}`
            }
            return label
          },
          title: (tooltipItems) => {
            const timestamp = tooltipItems[0].parsed.x
            const formattedDate = new Date(timestamp).toLocaleString()
            return formattedDate
          },

        },
      },
      legend: {
        labels: {
          color: darkMode ? 'white' : 'black',
        },
      },
    },
  }

  return (
    <div className="card border-0 shadow-none">

      <div className="card-body p-0" style={{ height: '300px' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
      {interconnectGraphQuery.isLoading && (
        <div className={`overlay ${darkMode ? 'dark' : ''}`}>
          <i className="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
      )}
      {interconnectGraphQuery.isError && (
        <div className={`overlay ${darkMode ? 'dark' : ''}`}>
          <div className="row">
            <div className="col-1">
              <i
                className="fas fa-2x fa-exclamation-triangle text-danger"
                style={{
                  position: 'relative',
                  top: '50%',
                  transform: 'translatey(-50%)',
                }}
              >
              </i>
            </div>
            <div
              className="col-11"
              style={{
                paddingLeft: '20px',
              }}
            >
              <h3 className="text-danger">Er is iets misgegaan</h3>
              <p
                className="text-danger"
                style={{
                  margin: '0',
                // }}>Er is iets misgegaan bij het ophalen van de modems. Probeer het later opnieuw.</p>
                }}
              >
                Het is op dit moment niet mogelijk om de grafiek te laden, of er is geen beschikbaar. Probeer het later nog een keer.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
