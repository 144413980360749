import React, { useContext, useEffect } from 'react'
import {
  Outlet,
  Route,
  Routes,
  useParams,
  useResolvedPath,
} from 'react-router-dom'
import SecondaryNavContext from '../../../components/SecondaryNav/SecondaryNavContext'
import { NetworkDevices } from './Devices'
import { NetworkDevice } from './[id]/Device'

export function DevicesModule() {
  // Compute the base path before the <Routes> object.
  // Note: This hook must be called within a Router context.
  const resolved = useResolvedPath('.')
  const basePath = resolved.pathname

  // Inline layout component that receives the basePath as a prop.
  const DevicesLayout = ({ basePath }: { basePath: string }) => {
    const { setLinks } = useContext(SecondaryNavContext)
    const { id } = useParams()

    useEffect(() => {
      if (id) {
        const deviceBase = `${basePath}/${id}`
        setLinks([
          { name: 'Overzicht', url: deviceBase },
          { name: 'Instellingen', url: `${deviceBase}/settings` },
          // Add more links as needed.
        ])
      }
      else {
        setLinks([])
      }
    }, [id, basePath, setLinks])

    return <Outlet />
  }

  return (
    <Routes>
      {/* Pass the computed basePath to DevicesLayout */}
      <Route element={<DevicesLayout basePath={basePath} />}>
        <Route path=":id/settings" element={<div>Settings</div>} />
        <Route path=":id" element={<NetworkDevice />} />
        {/* The index route serves as the overview */}
        <Route index element={<NetworkDevices />} />
      </Route>
      <Route path="*" element={<div>Not found</div>} />
    </Routes>
  )
}
