import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { getAccessToken } from '../../services/auth.service'
import { useUser } from '../../providers/UserProvider'
import type { PermissionsObject } from '../../util/checkPermission'
import { getPermissions } from '../../util/checkPermission'

interface Interconnect {
  id: string
  name: string
  description: string
  group: {
    id: string
    name: string
  }
  device?: {
    id: string
    name: string
  }
  port?: {
    id: string
    name: string
  }
  created: Date
  updated: Date
  deleted: Date
}

export function Interconnects() {
  const [interconnects, setInterconnects] = useState<Interconnect[]>([])
  const navigate = useNavigate()

  const { permissions } = useUser()
  const interconnectPermissions: PermissionsObject = getPermissions('/frontend/modules/netwerkbeheer/interconnects', permissions)

  const interconnectQuery = useQuery({
    queryKey: ['interconnects'],

    queryFn: async () => {
      const response = await fetch('/api/netwerk/interconnects', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  useEffect(() => {
    if (interconnectQuery.data) {
      setInterconnects(
        interconnectQuery.data.map((interconnect: any) => {
          const object = {
            id: interconnect.id,
            name: interconnect.name,
            description: interconnect.description,
            group: {
              id: interconnect.group.id,
              name: interconnect.group.name,
            },
            device: undefined,
            port: undefined,
            created: new Date(interconnect.created),
            updated: new Date(interconnect.updated),
            deleted: new Date(interconnect.deleted),
          } as Interconnect

          if (interconnect.device_port) {
            object.device = {
              id: interconnect.device_port.device.id,
              name: interconnect.device_port.device.name,
            }

            object.port = {
              id: interconnect.device_port.port.id,
              name: interconnect.device_port.port.name,
            }
          }

          return object
        }),
      )
    }
  }, [interconnectQuery.data])

  if (interconnectQuery.isLoading)
    return <div>Loading...</div>
  if (interconnectQuery.error)
    return <div>Error</div>

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">Interconnects</h3>
        <div className="card-tools">
          {interconnectPermissions.create && (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate('/netwerkbeheer/interconnects/toevoegen')}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </>
          )}

        </div>
      </div>
      <div className="card-body">
        <table className="table table-hover table-responsive text-nowrap">
          <thead>
            <tr>
              <th>Naam</th>
              <th>Groep</th>
              <th>Apparaat</th>
              <th>Poort</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {interconnects.map((interconnect) => {
              return (
                <tr
                  key={interconnect.id}
                  onDoubleClick={() => {
                    navigate(`/netwerkbeheer/interconnects/${interconnect.id}`)
                  }}
                >
                  <td>{interconnect.name}</td>
                  <td>{interconnect.group.name}</td>
                  <td>
                    {interconnect.device && (
                      <>
                        {interconnect.device.name}
                      </>
                    )}
                  </td>
                  <td>
                    {interconnect.port && (
                      <>
                        {interconnect.port.name}
                      </>
                    )}
                  </td>
                  <td>
                    {interconnect.description && interconnect.description.length > 0 && (
                      <>
                        <FontAwesomeIcon icon={faInfoCircle} className="ml-1" id={`tooltip-${interconnect.id}`} />
                        <Tooltip anchorSelect={`#tooltip-${interconnect.id}`}>
                          {interconnect.description}
                        </Tooltip>
                      </>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
