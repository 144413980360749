import { Route, Routes } from 'react-router-dom'
import { OutageDashboard } from './Dashboard'
import { Outage } from './[id]/Outage'

export function OutagesModule() {
  return (
    <Routes>
      <Route path="/:id" element={<Outage />} />
      <Route path="/" element={<OutageDashboard />} />
      <Route path="*" element={<div>Not found</div>} />
    </Routes>
  )
}
