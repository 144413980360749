import React from 'react'
import type { SecondaryNavContextValue } from './types'

const defaultValue: SecondaryNavContextValue = {
  links: [],
  setLinks: () => {
    // do nothing by default
  },
}

const SecondaryNavContext = React.createContext<SecondaryNavContextValue>(defaultValue)

export default SecondaryNavContext
