import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Alert, Card, Spinner } from 'react-bootstrap'
import { getAccessToken } from '../../../../services/auth.service'
import { InterconnectBandwidthCard } from './InterconnectBandwidthCard'

export interface InterconnectResponse {
  id: number
  name: string
  description: string
  group: {
    id: number
    name: string
  }
  created: Date
  updated: Date
  deleted: Date | null
  device: {
    name: string
    port: string
  }
}

export function Interconnect() {
  const { id } = useParams()

  if (!id)
    return null

  const [interconnect, setInterconnect] = useState<InterconnectResponse | null>(null)

  const interconnectQuery = useQuery({
    queryKey: ['interconnect', id],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/interconnects/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  useEffect(() => {
    if (interconnectQuery.data) {
      setInterconnect(interconnectQuery.data)
    }
  }, [interconnectQuery.data])

  if (interconnectQuery.isLoading || !interconnect) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }

  if (interconnectQuery.isError) {
    return (
      <Alert variant="danger">
        Error:
        {interconnectQuery.error?.message}
      </Alert>
    )
  }

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>{interconnect.name}</Card.Title>
        <Card.Text>{interconnect.description}</Card.Text>
        <ul className="list-unstyled">
          <li>
            <strong>Bedrijf:</strong>
            {' '}
            {interconnect.group.name}
          </li>
          <li>
            <strong>Device:</strong>
            {' '}
            {interconnect.device.name}
          </li>
          <li>
            <strong>Port:</strong>
            {' '}
            {interconnect.device.port}
          </li>
          {interconnect.deleted && (
            <li>
              <strong>Deleted:</strong>
              {' '}
              {new Date(interconnect.deleted).toLocaleString()}
            </li>
          )}
        </ul>

        <InterconnectBandwidthCard id={id} />
      </Card.Body>
    </Card>
  )
}
