import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHatWizard, faPlus } from '@fortawesome/free-solid-svg-icons'
import { getAccessToken } from '../../services/auth.service'
import type { PermissionsObject } from '../../util/checkPermission'
import { getPermissions } from '../../util/checkPermission'
import { useUser } from '../../providers/UserProvider'
import { getWorkflowStatus, prettyProduct, prettySpeed } from '../../util/helpers'

interface WorkflowStatus {
  name: string
  description?: string
  index: number
  notes?: string
  status: string
  created_at: string
}

interface Aansluiting {
  adres: {
    id: string
    streetName: string
    homeNumber: number
    homeLetter: string
    homeAddition: string
    street: string
    city: string
    zipcode: string
  }
  company: {
    id: string
    name: string
  }
  connection: {
    id: string
    type: string
    speed: number
    active: number
    workflow_id: string
    workflow: {
      id: string
      status: WorkflowStatus[]
    }
  }
}

interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: Aansluiting[]
}

export function Aansluitingen() {
  // Query filters and pagination
  const [limit, setLimit] = React.useState(25)
  const [page, setPage] = React.useState(0)
  const [nameFilter, setNameFilter] = React.useState('')
  const [adressFilter, setAdressFilter] = React.useState('')
  const [zipcodeFilter, setZipcodeFilter] = React.useState('')
  const [cityFilter, setCityFilter] = React.useState('')

  const { permissions } = useUser()
  const connectionPermissions: PermissionsObject = getPermissions('/frontend/modules/netwerkbeheer/aansluitingen', permissions)

  const [connections, setConnections] = React.useState<ApiResponse>({
    total_rows: 0,
    total_pages: 0,
    page: 0,
    result: [],
  })

  const navigate = useNavigate()

  const aansluitingQuery = useQuery({
    queryKey: ['aansluitingen', {
      limit,
      page,
      nameFilter,
      adressFilter,
      zipcodeFilter,
      cityFilter,
    }],

    queryFn: async () => {
      let queryString = `/api/netwerk/aansluitingen?limit=${limit}&page=${page}`

      if (nameFilter)
        queryString += `&companyName=${encodeURIComponent(nameFilter)}`
      if (adressFilter)
        queryString += `&adress=${encodeURIComponent(adressFilter)}`
      if (zipcodeFilter)
        queryString += `&zipcode=${encodeURIComponent(zipcodeFilter)}`
      if (cityFilter)
        queryString += `&city=${encodeURIComponent(cityFilter)}`

      const response = await fetch(queryString, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  React.useEffect(() => {
    if (aansluitingQuery.data) {
      setConnections(aansluitingQuery.data)
    }
  }, [aansluitingQuery.data])

  const onLimitChange = (event: { target: { value: string } }) => {
    setLimit(Number.parseInt(event.target.value))

    // Calculate current index
    const currentIndex = page * limit

    // Calculate new offset
    const newOffset = Math.floor(currentIndex / Number.parseInt(event.target.value))

    setPage(newOffset)
  }

  // Reset pagination when filters change
  React.useEffect(() => {
    setPage(0)
  }, [nameFilter, adressFilter, zipcodeFilter, cityFilter])

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          Aansluitingen (
          {connections.total_rows}
          )
        </h3>
        <div className="card-tools">
          {connectionPermissions.create && (
            <>
              <button type="button" className="btn btn-primary" onClick={() => navigate('/netwerkbeheer/aansluitingen/bulk_toevoegen')}>
                <FontAwesomeIcon icon={faHatWizard} />
              </button>
              {' '}
&nbsp;
              <button type="button" className="btn btn-primary" onClick={() => navigate('/netwerkbeheer/aansluitingen/toevoegen')}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </>
          )}
        </div>
      </div>
      <div className="card-body table-responsive p-0">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Bedrijfsnaam</th>
              <th>Adres</th>
              <th>Postcode</th>
              <th>Plaats</th>
              <th>Product</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {/* Filters */}
            <tr>
              <td>
                <input type="text" className="form-control" value={nameFilter} onChange={event => setNameFilter(event.target.value)} />
              </td>
              <td></td>
              {/* <td>
                <input type='text' className='form-control' value={adressFilter} onChange={(event) => setAdressFilter(event.target.value)} />
              </td> */}
              <td>
                <input type="text" className="form-control" value={zipcodeFilter} onChange={event => setZipcodeFilter(event.target.value)} />
              </td>
              <td>
                <input type="text" className="form-control" value={cityFilter} onChange={event => setCityFilter(event.target.value)} />
              </td>
              <td></td>
              <td></td>
            </tr>
            {/* Data */}
            {connections.result.map((aansluiting: Aansluiting) => {
              return (
                <tr key={aansluiting.connection.id} onDoubleClick={() => { navigate(`/netwerkbeheer/aansluitingen/${aansluiting.connection.id}`) }}>
                  <td>{aansluiting.company.name}</td>
                  <td>{aansluiting.adres.street}</td>
                  <td>{aansluiting.adres.zipcode}</td>
                  <td>{aansluiting.adres.city}</td>
                  <td>
                    {prettyProduct(aansluiting.connection.type)}
                    {' '}
                    {prettySpeed(aansluiting.connection.speed)}
                  </td>
                  <td>
                    <span
                      className="badge badge-secondary"
                      style={{
                        fontSize: '1rem',
                      }}
                    >
                      {getWorkflowStatus(aansluiting.connection.workflow.status)}
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
          {/* Table pagination / result limiter */}
          <tfoot>
            <tr>
              <td colSpan={7}>
                <div className="float-right">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {/*

                        Previous button, disabled if page is 0
                        -2 button, hidden if page is 0 or 1, or connections is not available
                        -1 button, hidden if page is 0, or connections is not available
                        Current page button, active
                        +1 button, hidden if page is last page, or connections is not available
                        +2 button, hidden if page is last page or last page - 1, or connections is not available
                        Next button, disabled if page is last page, or connections is not available
                      */}
                      <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page - 1)}>&laquo;</button>
                      </li>
                      <li className={`page-item ${page === 0 || page === 1 || !connections ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page - 2)}>{page - 1}</button>
                      </li>
                      <li className={`page-item ${page === 0 || !connections ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page - 1)}>{page}</button>
                      </li>
                      <li className="page-item active">
                        <button className="page-link">{page + 1}</button>
                      </li>
                      <li className={`page-item ${page === Math.ceil(connections?.total_rows / limit) - 1 || !connections ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page + 1)}>{page + 2}</button>
                      </li>
                      <li className={`page-item ${page === Math.ceil(connections?.total_rows / limit) - 1 || page === Math.ceil(connections?.total_rows / limit) - 2 || !connections ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page + 2)}>{page + 3}</button>
                      </li>
                      <li className={`page-item ${page === Math.ceil(connections?.total_rows / limit) - 1 || !connections ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page + 1)}>&raquo;</button>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="float-left">
                  <div className="form-group">
                    <label htmlFor="limit">Resultaten per pagina</label>
                    <select className="form-control" id="limit" onChange={onLimitChange} value={limit}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default Aansluitingen
