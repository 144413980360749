import { Route, Routes } from 'react-router-dom'
import { OutagesModule } from './outages/OutagesModule'

export function MeldingenModule() {
  return (
    <Routes>
      <Route path="storingen/*" element={<OutagesModule />} />
      <Route path="*" element={<div>Not found</div>} />
    </Routes>
  )
}
