import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { useMutation, useQuery } from '@tanstack/react-query'
import Select from 'react-select'
import { getAccessToken } from '../../../../../../../services/auth.service'
import { selectTheme } from '../../../../../../../util/helpers'
import { DarkModeContext } from '../../../../../../../providers/DarkModeProvider'

interface VoipLineAccount {
  id: number
  extension: number
  label: string
}

interface Option {
  value: number
  label: string
}

interface AddLineModalProps {
  show: boolean
  onHide: () => void
  toestelId: string // used in the POST endpoint: /voip/toestellen/:toestelId/lines
  voipCustomer: { id: number } | null
  onLineAdded?: () => void
  initialLine?: number
}

export const AddLineModal: React.FC<AddLineModalProps> = ({
  show,
  onHide,
  toestelId,
  voipCustomer,
  onLineAdded,
  initialLine,
}) => {
  const [line, setLine] = useState<number>(initialLine ?? 2)
  const [voipLine, setVoipLine] = useState<Option | null>(null)
  const [formError, setFormError] = useState<string | null>(null)

  const darkMode = useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  // Fetch available VoIP accounts.
  const {
    data: accountsData,
    isLoading: accountsLoading,
    error: accountsError,
  } = useQuery<VoipLineAccount[]>({
    queryKey: ['voipPhones', voipCustomer?.id, 'accounts'],
    enabled: voipCustomer !== null,
    queryFn: async () => {
      const response = await fetch(`/api/voip/klanten/${voipCustomer?.id}/accounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  const accountsOptions: Option[] = accountsData
    ? accountsData.map(account => ({
      value: account.id,
      label: `${account.extension} - ${account.label}`,
    }))
    : []

  useEffect(() => {
    if (!voipLine && accountsOptions.length > 0) {
      setVoipLine(accountsOptions[0])
    }
  }, [accountsOptions, voipLine])

  // Mutation for adding a new line.
  const addLineMutation = useMutation({
    mutationFn: async (data: { line: number, voipLine: number }) => {
      const response = await fetch(`/api/voip/toestellen/${toestelId}/lines`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
      })
      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(errorText || 'Error adding line')
      }
      return response.json()
    },
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormError(null)
    if (!voipLine) {
      setFormError('Please select a VoIP account.')
      return
    }
    addLineMutation.mutate(
      { line, voipLine: voipLine.value },
      {
        onSuccess: () => {
          onLineAdded && onLineAdded()
          onHide()
        },
        onError: (error: any) => {
          setFormError(error.message || 'Failed to add line.')
        },
      },
    )
  }

  // Reset form state when closing the modal.
  const handleClose = () => {
    setLine(initialLine ?? 2)
    setVoipLine(null)
    setFormError(null)
    onHide()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Line</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <Alert variant="danger">{formError}</Alert>}
          <Form.Group controlId="lineNumber" className="mb-3">
            <Form.Label>Line Number</Form.Label>
            <Form.Control
              type="number"
              value={line}
              onChange={e => setLine(Number(e.target.value))}
              placeholder="Enter line number"
              required
            />
          </Form.Group>
          <Form.Group controlId="voipLineSelect" className="mb-3">
            <Form.Label>VoIP Account</Form.Label>
            {accountsLoading
              ? (
                  <div>Loading accounts...</div>
                )
              : accountsError
                ? (
                    <Alert variant="danger">Error loading accounts.</Alert>
                  )
                : (
                    <Select
                      theme={selectStyle}
                      options={accountsOptions}
                      value={voipLine}
                      onChange={selectedOption => setVoipLine(selectedOption as Option)}
                      placeholder="Select a VoIP account"
                    />
                  )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={addLineMutation.status === 'pending'}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={addLineMutation.status === 'pending'}
          >
            {addLineMutation.status === 'pending' ? 'Adding...' : 'Add Line'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
