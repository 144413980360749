import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import md5 from 'md5'

import type { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faBuilding, faBuildingFlag, faCircleNodes, faDiagramProject, faFileInvoice, faGears, faLock, faMapLocationDot, faNetworkWired, faPlugCircleXmark, faRecycle, faRoute, faScrewdriverWrench, faSignsPost, faSquarePlus, faTableColumns, faUser, faUserGroup, faUserTie } from '@fortawesome/free-solid-svg-icons'

import { faBells, faMagnifyingGlassLocation, faPhoneOffice, faTrianglePersonDigging } from '@fortawesome/pro-solid-svg-icons'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useUser } from '../../../providers/UserProvider'
import { checkPermission } from '../../../util/checkPermission'
import MenuItem from '../../../components/MenuItem'
import logo from './logo512.png'

import './MenuSidebar.css'

export interface IMenuItem {
  name: string
  permission?: string
  exact: boolean
  path?: string
  icon?: IconDefinition | IconProp
  children?: Array<IMenuItem>
}

export const MENU: IMenuItem[] = [
  {
    name: 'Dashboard',
    icon: faTableColumns,
    path: '/',
    exact: true,
  },
  {
    name: 'BAG',
    exact: false,
    permission: '/frontend/modules/bag',
    icon: faSignsPost,
    children: [
      {
        icon: faMapLocationDot,
        name: 'Alle Adressen',
        exact: false,
        path: '/bag/adressen',
      },
      {
        icon: faMagnifyingGlassLocation,
        name: 'BU Finder',
        exact: false,
        path: '/bag/bufinder',
      },
    ],
  },
  {
    name: 'Netwerkbeheer',
    exact: false,
    permission: '/frontend/modules/netwerkbeheer',
    icon: faRoute,
    children: [
      {
        name: 'Aansluitingen',
        exact: false,
        permission: '/frontend/modules/netwerkbeheer/aansluitingen',
        icon: faBuildingFlag,
        path: '/netwerkbeheer/aansluitingen',
      },
      {
        name: 'Interconnects',
        exact: false,
        permission: '/frontend/modules/netwerkbeheer/interconnects',
        icon: faCircleNodes,
        path: '/netwerkbeheer/interconnects',
      },
      {
        name: 'EVC\'s',
        exact: false,
        permission: '/frontend/modules/netwerkbeheer/evcs',
        icon: faNetworkWired,
        path: '/netwerkbeheer/evcs',
      },
      {
        name: 'Apparaten',
        exact: false,
        permission: '/frontend/modules/netwerkbeheer/apparaten',
        icon: faNetworkWired,
        path: '/netwerkbeheer/apparaten',
      },
      {
        name: 'IP Adressen',
        exact: false,
        permission: '/modules/netwerkbeheer/ipadressen',
        icon: faNetworkWired,
        path: '/netwerkbeheer/ipadressen',
      },
    ],
  },
  {
    name: 'VOIP',
    exact: false,
    permission: '/frontend/modules/voip',
    icon: faPhoneOffice as IconProp,
    children: [
      {
        name: 'Klanten',
        exact: false,
        permission: '/frontend/modules/voip/klanten',
        icon: faBuilding,
        path: '/voip/klanten',
      },
    ],
  },
  {
    name: 'Klanten',
    exact: false,
    permission: '/frontend/modules/klanten',
    icon: faUserTie,
    path: '/customers',
  },
  {
    name: 'Projecten',
    path: '/projecten',
    permission: '/frontend/modules/projecten',
    exact: false,
    icon: faDiagramProject,
  },
  {
    name: 'Provisioning',
    exact: false,
    permission: '/frontend/modules/provisioning',
    icon: faGears,
    children: [
      {
        name: 'Routers',
        exact: false,
        icon: faRoute,
        path: '/provisioning/routers',
      },
    ],
  },
  {
    name: 'Meldingen',
    exact: false,
    permission: '/frontend/modules/meldingen',
    icon: faBells,
    children: [
      {
        name: 'Onderhoud',
        exact: false,
        permission: '/modules/meldingen/onderhoud',
        icon: faTrianglePersonDigging,
        path: '/meldingen/onderhoud',
      },
      {
        name: 'Storingen',
        exact: false,
        permission: '/modules/meldingen/storing',
        icon: faPlugCircleXmark,
        path: '/meldingen/storingen',
      }
    ]
  },
  {
    name: 'Administratie',
    exact: false,
    permission: '/frontend/modules/administratie',
    icon: faRecycle,
    children: [
      {
        name: 'Urenregistratie',
        exact: false,
        permission: '/frontend/modules/administratie/urenregistratie',
        icon: faRecycle,
        path: '/administratie/urenregistratie',
      },
    ],
  },
  {
    name: 'Tools',
    exact: false,
    permission: '/frontend/modules/tools',
    icon: faScrewdriverWrench,
    children: [
      {
        name: 'Belkosten2Exact',
        exact: false,
        permission: '/frontend/modules/tools/belkosten2exact',
        icon: faFileInvoice,
        path: '/tools/belkosten2exact',
      },
      {
        name: 'Gpon Tool',
        exact: false,
        permission: '/frontend/modules/tools/gponpreprocessor',
        icon: faSquarePlus,
        path: '/tools/gponpreprocessor',
      },
    ],
  },
  {
    name: 'Kaart',
    exact: false,
    permission: '/frontend/modules/kaart',
    icon: faMapLocationDot,
    path: '/kaart',
  },
  {
    name: 'Instellingen',
    exact: false,
    permission: '/frontend/modules/instellingen',
    icon: faGears,
    path: '/settings',
    children: [
      {
        name: 'Toegangsbeheer',
        exact: false,
        permission: '/frontend/modules/instellingen/gebruikers',
        icon: faLock,
        path: '/settings/toegang',
        children: [
          {
            name: 'Gebruikers',
            exact: false,
            permission: '/frontend/modules/instellingen/gebruikers',
            icon: faUser,
            path: '/settings/toegang/gebruikers',
          },
          {
            name: 'Groepen',
            exact: false,
            permission: '/frontend/modules/instellingen/groepen',
            icon: faUserGroup,
            path: '/settings/toegang/groepen',
          },
        ],
      },
    ],
  },
]

interface MenuSidebarProps {
  sidebar: {
    get: boolean
    set: Dispatch<SetStateAction<boolean>>
  }
}

function MenuSidebar(props: MenuSidebarProps) {
  // const user = useSelector((state: any) => state.auth.currentUser);
  // const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  // const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  // const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);
  const { user, permissions } = useUser()

  const isDev = process.env.NODE_ENV === 'development'
  const sidebarSkin = isDev ? 'sidebar-dark-red' : 'sidebar-dark-blue'
  const menuItemFlat = false
  const menuChildIndent = true

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link">
        <img
          src={logo}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: '.8' }}
        />
        <span className="brand-text font-weight-light">Datafiber.Network</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={`https://www.gravatar.com/avatar/${md5(user.email ? user.email : 'operations@datafiber.nl')}?s=160&d=retro&r=pg`}
              className="img-circle elevation-2"
              alt="User"
            />
          </div>
          <div className="info">
            <Link to="/profile" className="d-block">
              {user.firstName}
              {' '}
              {user.lastName}
            </Link>
          </div>
        </div>
        <nav className="mt-2" style={{ overflowY: 'hidden' }}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${menuItemFlat ? ' nav-flat' : ''
              }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {MENU.map((menuItem: IMenuItem) => {
              if (menuItem.permission && checkPermission(menuItem.permission, 'read', permissions) === false) {
                return null
              }

              return (
                <MenuItem key={menuItem.name} menuItem={menuItem} />
              )
            })}
          </ul>
        </nav>
      </div>
    </aside>
  )
}

export default MenuSidebar
