import React from 'react'
import { DateTime } from 'luxon'
import { default as packageInfo } from '../../../../../package.json'

function Footer() {
  return (
    <footer className="main-footer d-flex flex-column flex-sm-row justify-content-between align-items-center">
      <div>
        <strong>
          <span>
            Copyright © 2022 -
            {' '}
            {DateTime.now().toFormat('y')}
            {' '}
          </span>
          Datafiber Telecom B.V.
        </strong>
      </div>
      <div>
        <b>Versie:</b>
        <span>
&nbsp;
          {packageInfo.version}
        </span>
      </div>
    </footer>
  )
}

export default Footer
