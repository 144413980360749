import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { getAccessToken } from '../../../../services/auth.service'
import { VoipCustomerPhones } from './VoipCustomerPhones'

/**
 * {"id":240021,"name":"Datafiber","packageType":"maatwerk","managed":true,"phonebook":true,"created":"2024-06-06T12:05:19.476Z","updated":"2024-06-06T12:06:01.000Z","toestellen":[{"id":"805ec0a0171f","lines":[{"macaddress":"805ec0a0171f","index":1,"extension":214,"username":368170021,"name":"Brandon deskphone"}]}]}
 */

interface APIResponse {
  id: number
  name: string
  partner: string
  packageType: string
  managed: boolean
  phonebook: boolean
  created: string
  updated: string
}

export function VoipCustomer() {
  const { id } = useParams()

  const [voipCustomer, setVoipCustomer] = React.useState<APIResponse | null>(null)

  const voipCustomerQuery = useQuery({
    queryKey: ['voipCustomer', id],

    queryFn: async () => {
      const response = await fetch(`/api/voip/klanten/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json
    },
  })

  React.useEffect(() => {
    if (voipCustomerQuery.data) {
      setVoipCustomer(voipCustomerQuery.data)
    }
  }, [voipCustomerQuery.data])

  if (!voipCustomer) {
    return (
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">VoIP klant</h5>
        </div>
        <div className="card-body">
          <div className="alert alert-info" role="alert">
            Loading...
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">VoIP klant</h5>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              <tr>
                <th scope="row">Partner</th>
                <td>{voipCustomer.partner}</td>
              </tr>
              <tr>
                <th scope="row">Naam</th>
                <td>{voipCustomer.name}</td>
              </tr>
              <tr>
                <th scope="row">Pakket type</th>
                <td>{voipCustomer.packageType}</td>
              </tr>
              <tr>
                <th scope="row">Managed</th>
                <td>{voipCustomer.managed ? 'Ja' : 'Nee'}</td>
              </tr>
              <tr>
                <th scope="row">Telefoonboek</th>
                <td>{voipCustomer.phonebook ? 'Ja' : 'Nee'}</td>
              </tr>
              <tr>
                <th scope="row">Aangemaakt</th>
                <td>{new Date(voipCustomer.created).toLocaleString()}</td>
              </tr>
              <tr>
                <th scope="row">Laatst bijgewerkt</th>
                <td>{new Date(voipCustomer.updated).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <VoipCustomerPhones />
      </div>
    </div>
  )
}
