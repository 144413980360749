import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { faPlus, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getAccessToken } from '../../../services/auth.service'
import { VoipKlantToevoegenModal } from './KlantToevoegenModal'

enum VoipPackageType {
  MAATWERK = 'maatwerk',
  COMPLEET = 'compleet',
}

interface VoipAccount {
  id: string
  packageType: VoipPackageType
  name: string
  partner: string
}

interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: VoipAccount[]
}

export function VoipCustomers() {
  const [limit, setLimit] = React.useState(25)
  const [page, setPage] = React.useState(0)
  const [nameFilter, setNameFilter] = React.useState('')
  const [accounts, setAccounts] = React.useState<ApiResponse>({
    total_rows: 0,
    total_pages: 0,
    page: 0,
    result: [],
  })

  const [addCustomerModalVisible, setAddCustomerModalVisible] = React.useState(false)
  const [reloadModalVisible, setReloadModalVisible] = React.useState(false)

  const navigate = useNavigate()

  const accountsQuery = useQuery({
    queryKey: ['voipCustomers', {
      limit,
      page,
      nameFilter,
    }],

    queryFn: async () => {
      let queryString = `/api/voip/klanten?limit=${limit}&page=${page}&managed=true`

      if (nameFilter)
        queryString += `&naam=${encodeURIComponent(nameFilter)}`

      const response = await fetch(queryString, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json() as ApiResponse
    },
  })

  React.useEffect(() => {
    if (accountsQuery.data) {
      setAccounts(accountsQuery.data)
    }
  }, [accountsQuery.data])

  const onLimitChange = (event: { target: { value: string } }) => {
    setLimit(Number.parseInt(event.target.value))

    // Calculate current index
    const currentIndex = page * limit

    // Calculate new offset
    const newOffset = Math.floor(currentIndex / Number.parseInt(event.target.value))

    setPage(newOffset)
  }

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">Beheerde VOIP Klanten</h3>
        <div className="card-tools">
          <button type="button" className="btn btn-primary m-1" onClick={() => setAddCustomerModalVisible(true)}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button type="button" className="btn btn-primary m-1" onClick={() => setReloadModalVisible(true)}>
            <FontAwesomeIcon icon={faRepeat} />
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive p0">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Partner</th>
                <th>Naam</th>
                <th>Pakket</th>
              </tr>
            </thead>
            <tbody>
              {/* Filters */}
              <tr>
                <td></td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Naam"
                    value={nameFilter}
                    onChange={e => setNameFilter(e.target.value)}
                  />
                </td>
                <td></td>
              </tr>
              {accounts.result.map(account => (
                <tr key={account.id} onDoubleClick={() => { navigate(`/voip/klanten/${account.id}`) }}>
                  <td>{account.partner}</td>
                  <td>{account.name}</td>
                  <td>{account.packageType}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={7}>
                  <div className="float-right">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                          <button className="page-link" onClick={() => setPage(page - 1)}>&laquo;</button>
                        </li>
                        <li className={`page-item ${page === 0 || page === 1 || !accounts ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setPage(page - 2)}>{page - 1}</button>
                        </li>
                        <li className={`page-item ${page === 0 || !accounts ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setPage(page - 1)}>{page}</button>
                        </li>
                        <li className="page-item active">
                          <button className="page-link">{page + 1}</button>
                        </li>
                        <li className={`page-item ${page === Math.ceil(accounts?.total_rows / limit) - 1 || !accounts ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setPage(page + 1)}>{page + 2}</button>
                        </li>
                        <li className={`page-item ${page === Math.ceil(accounts?.total_rows / limit) - 1 || page === Math.ceil(accounts?.total_rows / limit) - 2 || !accounts ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setPage(page + 2)}>{page + 3}</button>
                        </li>
                        <li className={`page-item ${page === Math.ceil(accounts?.total_rows / limit) - 1 || !accounts ? 'disabled' : ''}`}>
                          <button className="page-link" onClick={() => setPage(page + 1)}>&raquo;</button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="float-left">
                    <div className="form-group">
                      <label htmlFor="limit">Resultaten per pagina</label>
                      <select className="form-control" id="limit" onChange={onLimitChange} value={limit}>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <VoipKlantToevoegenModal show={addCustomerModalVisible} setShowModal={setAddCustomerModalVisible} />
      <ReloadModal show={reloadModalVisible} setShowModal={setReloadModalVisible} />
    </div>
  )
}

function ReloadModal({ show, setShowModal }: {
  show: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const syncVoipCustomers = async () => {
    const result = await fetch('/api/voip/syncVoipgrid', {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${getAccessToken()}`,
      }),
    })

    if (!result.ok) {
      toast.error('Er is een fout opgetreden bij het ophalen van de voip klanten')
      return setShowModal(false)
    }

    toast.success('Voip klanten zijn succesvol opgehaald')
    setShowModal(false)
  }

  React.useEffect(() => {
    if (show) {
      syncVoipCustomers()
    }
  }, [show])

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Reload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Voipgrid informatie wordt opgehaald..</p>
        <p>Dit kan een paar minuten duren, dit scherm sluit automatisch.</p>
      </Modal.Body>
    </Modal>
  )
}
