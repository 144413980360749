import React from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import Login from './app/modules/login/Login'
import PrivateRoute from './app/guards/PrivateRoute'
import BackendOnlineGuard from './app/guards/BackendOnline'
import ForgotPassword from './app/modules/forgot-password/ForgotPassword'
import RecoverPassword from './app/modules/recover-password/RecoverPassword'
import PublicRoute from './app/guards/PublicRoute'
import Main from './app/modules/main/Main'
import BagAddresses from './app/pages/bag/Adressen'
import BagAddress from './app/pages/bag/[id]/Adres'
import Projects from './app/pages/projecten/page'
import Customers from './app/pages/customers/Customers'
import Customer from './app/pages/customers/[id]/Customer'
import { ProjectToevoegen } from './app/pages/projecten/Toevoegen'
import RouterProvisioning from './app/pages/provisioning/router/RouterProvisioning'
import RouterProvisioningToevoegen from './app/pages/provisioning/router/Toevoegen'
import { Router } from './app/pages/provisioning/router/[id]/Router'
import { Project } from './app/pages/projecten/[id]/Project'
import { UrenRegistratie } from './app/pages/administratie/UrenRegistratie'
import { UrenRegistratieToevoegen } from './app/pages/administratie/UrenRegistratie/Toevoegen'
import { VialerCsvProcessor } from './app/pages/tools/vialercsvprocessor'
import { Map } from './app/pages/map/Map'
import { ImportJob } from './app/pages/bag/import/[id]/ImportJob'
import { ImportJobs } from './app/pages/bag/import/Imports'
import { CustomerLostAndFound } from './app/pages/customers/LostAndFound'
import { GponPreProcessor } from './app/pages/tools/gponPreProcessor'
import { Dashboard } from './app/pages/dashboard/Dashboard'
import { VoipCustomers } from './app/pages/voip/klanten/VoipKlanten'
import { VoipCustomer } from './app/pages/voip/klanten/[id]/VoipKlant'
import { VoipToestel } from './app/pages/voip/klanten/[id]/toestellen/[id]/VoipToestel'
import { Gebruiker } from './app/pages/settings/toegang/gebruikers/[id]/Gebruiker'
import { Gebruikers } from './app/pages/settings/toegang/gebruikers/Gebruikers'
import { Groep } from './app/pages/settings/toegang/groepen/[id]/Groep'
import { Groepen } from './app/pages/settings/toegang/groepen/Groepen'
import { BusinessUnitFinder } from './app/pages/bag/BusinessUnitFinder'
import { PotentialProjects } from './app/pages/bag/[id]/PotentialProjects'
import { PotentialProject } from './app/pages/bag/[id]/PotentialProject'
import { NetwerkbeheerModule } from './app/pages/netwerkbeheer/Netwerkbeheer.module'
import { MeldingenModule } from './app/pages/meldingen/meldingenModule'

function App(): JSX.Element {
  return (
    <Routes>
      <Route path="/login" element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="/forgot-password" element={<PublicRoute />}>
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route path="/recover-password" element={<PublicRoute />}>
        <Route path="/recover-password" element={<RecoverPassword />} />
      </Route>
      <Route path="/" element={<BackendOnlineGuard />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            {/* Module BAG */}
            <Route path="/bag/adressen/:id" element={<BagAddress />} />
            <Route path="/bag/adressen" element={<BagAddresses />} />
            <Route path="/bag/import/:id" element={<ImportJob />} />
            <Route path="/bag/import" element={<ImportJobs />} />
            <Route path="/bag/bufinder/:id/:index/detail" element={<PotentialProject />} />
            <Route path="/bag/bufinder/:id/:index?" element={<PotentialProjects />} />
            <Route path="/bag/bufinder" element={<BusinessUnitFinder />} />

            {/* Module Netwerkbeheer */}
            <Route path="/netwerkbeheer/*" element={<NetwerkbeheerModule />} />

            {/* Module Meldingen */}
            <Route path="/meldingen/*" element={<MeldingenModule />} />

            {/* Module VOIP */}
            <Route path="/voip/klanten/:accountid/toestellen/:macaddress" element={<VoipToestel />} />
            <Route path="/voip/klanten/:id" element={<VoipCustomer />} />
            <Route path="/voip/klanten" element={<VoipCustomers />} />

            {/* Module Customers (zoho) */}
            <Route path="/customers/lostAndFound" element={<CustomerLostAndFound />} />
            <Route path="/customers/:id" element={<Customer />} />
            <Route path="/customers" element={<Customers />} />

            {/* Module Projects */}
            <Route path="/projecten" element={<Projects />} />
            <Route path="/projecten/toevoegen" element={<ProjectToevoegen />} />
            <Route path="/projecten/:id" element={<Project />} />

            {/* Module Provisioning */}
            <Route path="/provisioning/routers" element={<RouterProvisioning />} />
            <Route path="/provisioning/routers/toevoegen" element={<RouterProvisioningToevoegen />} />
            <Route path="/provisioning/routers/:id" element={<Router />} />

            {/* Module Administratie */}
            <Route path="/administratie/urenregistratie" element={<UrenRegistratie />} />
            <Route path="/administratie/urenregistratie/toevoegen" element={<UrenRegistratieToevoegen />} />

            {/* Module Tools */}
            <Route path="/tools/belkosten2exact" element={<VialerCsvProcessor />} />
            <Route path="/tools/gponpreprocessor" element={<GponPreProcessor />} />

            {/* Module Map */}
            <Route path="/kaart" element={<Map />} />

            {/* Module Settings */}
            <Route path="/settings/toegang/gebruikers/:id" element={<Gebruiker />} />
            <Route path="/settings/toegang/gebruikers" element={<Gebruikers />} />
            <Route path="/settings/toegang/groepen/:id" element={<Groep />} />
            <Route path="/settings/toegang/groepen" element={<Groepen />} />

            {/* Module Dashboard */}

            <Route path="/" element={<Dashboard />} />

            <Route path="test/" element={<div>test</div>} />
            <Route path="*" element={<div>Not found</div>} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default App
