// SecondaryNavProvider.jsx
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { ReactNode } from 'react';
import SecondaryNavContext from './SecondaryNavContext'

interface SecondaryNavProviderProps {
  children: ReactNode
}

export function SecondaryNavProvider({ children }: SecondaryNavProviderProps) {
  interface Link {
    name: string
    url: string
  }

  const [links, setLinks] = useState<Link[]>([])
  const location = useLocation()

  useEffect(() => {
    if (links.length > 0) {
      const currentUrls = links.map(link => link.url)
      if (!currentUrls.includes(location.pathname)) {
        setLinks([])
      }
    }
  }, [location, links])

  return (
    <SecondaryNavContext.Provider value={{ links, setLinks }}>
      {children}
    </SecondaryNavContext.Provider>
  )
}
