import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import { getAccessToken } from '../../../../services/auth.service'
import { selectTheme } from '../../../../util/helpers'

interface Phones {
  id: string
  customerId: string
  vendor: string
  model: string
  lines: {
    id: string
    extension: number
    label: string
  }[]
}

interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: Phones[]
}

interface VoipKlantApiResponse {
  id: number
  name: string
  packageType: string
  managed: boolean
  phonebook: boolean
  created: string
  updated: string
}

export function VoipCustomerPhones() {
  const { id } = useParams()
  const navigate = useNavigate()

  const [voipPhones, setVoipPhones] = React.useState<ApiResponse | null>(null)
  const [modalOpen, setModalOpen] = React.useState(false)

  const voipPhonesQuery = useQuery({
    queryKey: ['voipPhones', id],

    queryFn: async () => {
      const response = await fetch(`/api/voip/toestellen?voipCustomer=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json as ApiResponse
    },
  })

  React.useEffect(() => {
    if (voipPhonesQuery.data) {
      setVoipPhones(voipPhonesQuery.data)
    }
  }, [voipPhonesQuery.data])

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Toestellen</h5>
          <div className="card-tools">
            <button type="button" className="btn btn-primary" onClick={() => { setModalOpen(true) }}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Model</th>
                  <th>Extensies</th>
                </tr>
              </thead>
              <tbody>
                {voipPhones?.result.map(phone => (
                  <tr key={phone.id} onDoubleClick={() => { navigate(`/voip/klanten/${id}/toestellen/${phone.id}`) }}>
                    <td>
                      {phone.vendor.charAt(0).toUpperCase()}
                      {phone.vendor.slice(1)}
                      {' '}
                      {phone.model}
                    </td>
                    <td>
                      {phone.lines.map(line => (
                        <div key={line.id}>
                          {line.extension}
                          {' '}
                          -
                          {line.label}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AddVoipCustomerPhone modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  )
}

interface selectType {
  value: string | object
  label: string
}

interface VoipLinesResponse {
  id: string
  extension: number
  label: string
}

function AddVoipCustomerPhone({ modalOpen, setModalOpen }: { modalOpen: boolean, setModalOpen: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element {
  const { id } = useParams()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const [macaddress, setMacaddress] = React.useState('')
  const [model, setModel] = React.useState<selectType | null>(null)
  const [voipLine, setVoipLine] = React.useState<selectType | null>(null)

  const [voipClientSearch, setVoipClientSearch] = React.useState('')

  const [macaddressState, setMacaddressState] = React.useState < 'valid' | 'invalid' | 'managedbyother' | 'duplicate' | null>(null)
  const [models, setModels] = React.useState<selectType[]>([])
  const [voipCustomer, setVoipCustomer] = React.useState<VoipKlantApiResponse | null>(null)
  const [voipLines, setVoipLines] = React.useState<selectType[]>([])

  const macaddressStateQuery = useQuery({
    queryKey: ['macaddressState', macaddress],

    queryFn: async () => {
      if (macaddress.length < 12) {
        return null
      }

      const response = await fetch(`/api/voip/toestellen/${macaddress}/zerotouch`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json
    },
  })

  React.useEffect(() => {
    if (macaddressStateQuery.data) {
      setMacaddressState(macaddressStateQuery.data.status)
    }
  })

  const modelsQuery = useQuery({
    queryKey: ['voipPhones', 'models'],

    queryFn: async () => {
      const response = await fetch(`/api/voip/toestellen/models`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json
    },
  })

  React.useEffect(() => {
    if (modelsQuery.data) {
      setModels(modelsQuery.data.map((model: any) => ({
        value: model.id,
        label: `${model.vendor.charAt(0).toUpperCase()}${model.vendor.slice(1)} ${model.name}`,
      })),
      )
    }
  }, [modelsQuery.data])

  const voipCustomerQuery = useQuery({
    queryKey: ['voipCustomer', id],

    queryFn: async () => {
      const response = await fetch(`/api/voip/klanten/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json
    },
  })

  React.useEffect(() => {
    if (voipCustomerQuery.data) {
      setVoipCustomer(voipCustomerQuery.data)
    }
  }, [voipCustomerQuery.data])

  const voipLinesQuery = useQuery({
    queryKey: ['voipPhones', id, 'voipLines'],
    enabled: voipCustomer !== null,
    queryFn: async () => {
      const response = await fetch(`/api/voip/klanten/${voipCustomer?.id}/accounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json as VoipLinesResponse[]
    },
  })

  React.useEffect(() => {
    if (voipLinesQuery.data) {
      setVoipLines(voipLinesQuery.data.map(line => ({
        value: line.id,
        label: `${line.extension} - ${line.label}`,
      })))
    }
  }, [voipLinesQuery.data])

  const savePhone = async () => {
    if (macaddressState !== 'valid' || !model || !voipLine) {
      return
    }

    const response = await fetch(`/api/voip/toestellen`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        macaddress,
        model: model.value,
        voipLine: voipLine.value,
      }),
    })

    if (!response.ok) {
      toast.error('Er is een fout opgetreden')
      return
    }
    else {
      toast.success('Toestel toegevoegd')
    }

    setModalOpen(false)
  }

  return (
    <Modal show={modalOpen} onHide={() => { setModalOpen(false) }} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Voeg toestel toe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>MAC-adres</Form.Label>
            <Form.Control type="text" value={macaddress} onChange={(e) => { setMacaddress(e.target.value) }} isValid={macaddressState === 'valid'} isInvalid={macaddressState === 'invalid' || macaddressState === 'duplicate' || macaddressState === 'managedbyother'} />
            {macaddressState === 'invalid' && <Form.Text className="text-danger">Ongeldig MAC-adres</Form.Text>}
            {macaddressState === 'duplicate' && <Form.Text className="text-danger">Dit MAC-adres is al in gebruik</Form.Text>}
            {macaddressState === 'managedbyother' && <Form.Text className="text-danger">Dit MAC-adres is al in beheer bij een andere leverancier</Form.Text>}
            {macaddressState === 'valid' && <Form.Text className="text-success">Geldig MAC-adres</Form.Text>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Model</Form.Label>
            <Select theme={selectStyle} options={models} value={model} onChange={(e) => { setModel(e) }} />
          </Form.Group>
          <Form.Group>
            <Form.Label>VoIP lijn</Form.Label>
            <Select theme={selectStyle} options={voipLines} value={voipLine} onChange={(e) => { setVoipLine(e) }} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => { setModalOpen(false) }}>
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={() => { savePhone() }}>
          Save changes
        </button>
      </Modal.Footer>
    </Modal>
  )
}
