import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from '../../../services/auth.service'
import type { OutageEntity } from './Types'
import { NewOutageModal } from './Modal'
import { OutageType } from './Types'

export function OutageDashboard() {
  const [outages, setOutages] = useState<OutageEntity[]>([])
  const [newModalOpen, setNewModalOpen] = useState(false)

  const navigate = useNavigate()

  const outagesQuery = useQuery({
    queryKey: ['outages'],
    queryFn: async () => {
      const response = await fetch('/api/notifications/outages', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      return response.json()
    },
  })

  useEffect(() => {
    if (outagesQuery.data && outagesQuery.data.length > 0) {
      setOutages(outagesQuery.data.map((outage: any) => {
        return {
          id: outage.id,
          title: outage.title,
          description: outage.description,
          status: outage.status,
          impact: outage.impact,
          severity: outage.severity,
          type: outage.type || OutageType.UNSCHEDULED, // Default to UNSCHEDULED if not provided
          start: new Date(outage.start),
          end: outage.end ? new Date(outage.end) : undefined,
        }
      }))
    }
  }, [outagesQuery.data])

  return (
    <>
      <Card className="card-outline card-primary">
        <Card.Header>
          <Card.Title>Storingen</Card.Title>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setNewModalOpen(true)}
            >
              Nieuwe storing
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Titel</th>
                <th>Start</th>
                <th>Eind</th>
                <th>Status</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {outages?.map(outage => (
                <tr key={outage.id} onDoubleClick={() => navigate(`/meldingen/storingen/${outage.id}`)}>
                  <td>{outage.title}</td>
                  <td>{`${outage.start.toLocaleDateString()} ${outage.start.toLocaleTimeString()}`}</td>
                  <td>
                    {outage.end && (
                    `${outage.end.toLocaleDateString()} ${outage.end.toLocaleTimeString()}`
                    )}
                  </td>
                  <td>{outage.status}</td>
                  <td>{outage.type}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <NewOutageModal show={newModalOpen} handleClose={() => setNewModalOpen(false)} />
    </>
  )
}
