import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, ButtonGroup, Card, Form, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faCalendar, faCheck, faEdit, faEyeSlash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { getAccessToken } from '../../../../services/auth.service'
import { type OutageEntity, OutageImpact, OutageSeverity, OutageStatus, OutageType } from '../Types'

/**
 * Helper function to format a Date as a local datetime string that the
 * datetime-local input expects (YYYY-MM-DDTHH:MM).
 */
function formatDateTimeLocal(date: Date): string {
  const pad = (n: number) => n.toString().padStart(2, '0')
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1) // Months are 0-based
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  return `${year}-${month}-${day}T${hours}:${minutes}`
}

/* --------------------------------------------------------------------------
   EditOutageModal
   -------------------------------------------------------------------------- */
interface EditOutageModalProps {
  show: boolean
  handleClose: () => void
  outage: OutageEntity
  handleSave: (updatedOutage: Partial<OutageEntity>) => Promise<void>
}

function EditOutageModal({ show, handleClose, outage, handleSave }: EditOutageModalProps) {
  const [title, setTitle] = useState(outage.title)
  const [description, setDescription] = useState(outage.description)
  const [impact, setImpact] = useState(outage.impact)
  const [severity, setSeverity] = useState(outage.severity)
  const [type, setType] = useState(outage.type)
  const [start, setStart] = useState(outage.start)
  // Allow end to be optional (Date or undefined)
  const [end, setEnd] = useState<Date | undefined>(outage.end)

  // Update form values when the outage prop changes
  useEffect(() => {
    setTitle(outage.title)
    setDescription(outage.description)
    setImpact(outage.impact)
    setSeverity(outage.severity)
    setType(outage.type)
    setStart(outage.start)
    setEnd(outage.end)
  }, [outage])

  const disabled = !title || !description || !impact || !severity || !type || !start

  const handleSubmit = async () => {
    await handleSave({ title, description, impact, severity, type, start, end })
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Outage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="editOutageTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="editOutageDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="editOutageType">
            <Form.Label>Type</Form.Label>
            <Form.Select
              value={type}
              onChange={e => setType(e.target.value as OutageType)}
            >
              <option value={OutageType.UNSCHEDULED}>Unscheduled</option>
              <option value={OutageType.SCHEDULED}>Scheduled</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="editOutageImpact">
            <Form.Label>Impact</Form.Label>
            <Form.Select
              value={impact}
              onChange={e => setImpact(e.target.value as OutageImpact)}
            >
              <option value={OutageImpact.LOCAL}>Local</option>
              <option value={OutageImpact.REGIONAL}>Regional</option>
              <option value={OutageImpact.NATIONAL}>National</option>
              <option value={OutageImpact.GLOBAL}>Global</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="editOutageSeverity">
            <Form.Label>Severity</Form.Label>
            <Form.Select
              value={severity}
              onChange={e => setSeverity(e.target.value as OutageSeverity)}
            >
              <option value={OutageSeverity.LOW}>Low</option>
              <option value={OutageSeverity.MEDIUM}>Medium</option>
              <option value={OutageSeverity.HIGH}>High</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="editOutageStart">
            <Form.Label>Start</Form.Label>
            <Form.Control
              type="datetime-local"
              value={formatDateTimeLocal(start)}
              onChange={e => setStart(new Date(e.target.value))}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="editOutageEnd">
            <Form.Label>End (Optional)</Form.Label>
            <Form.Control
              type="datetime-local"
              value={end ? formatDateTimeLocal(end) : ''}
              onChange={(e) => {
                if (e.target.value) {
                  setEnd(new Date(e.target.value))
                }
                else {
                  setEnd(undefined)
                }
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={disabled}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

/* --------------------------------------------------------------------------
   AddUpdateModal
   -------------------------------------------------------------------------- */
interface AddUpdateModalProps {
  show: boolean
  handleClose: () => void
  handleAddUpdate: (description: string) => Promise<void>
}

function AddUpdateModal({ show, handleClose, handleAddUpdate }: AddUpdateModalProps) {
  const [description, setDescription] = useState('')
  const disabled = !description

  const handleSubmit = async () => {
    await handleAddUpdate(description)
    setDescription('')
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="updateDescription" className="mb-3">
            <Form.Label>Update Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={disabled}>
          Add Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

/* --------------------------------------------------------------------------
   EditUpdateModal - For editing a single update
   -------------------------------------------------------------------------- */
interface EditUpdateModalProps {
  show: boolean
  handleClose: () => void
  update: {
    id: string
    description: string
    createdAt: Date
    updatedAt: Date
  }
  handleSave: (updatedDescription: string) => Promise<void>
}

function EditUpdateModal({ show, handleClose, update, handleSave }: EditUpdateModalProps) {
  const [description, setDescription] = useState(update.description)

  // Update local state if update prop changes.
  useEffect(() => {
    setDescription(update.description)
  }, [update])

  const disabled = !description

  const handleSubmit = async () => {
    await handleSave(description)
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="editUpdateDescription" className="mb-3">
            <Form.Label>Update Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={disabled}>
          Save Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

/* --------------------------------------------------------------------------
   Main Outage Component
   -------------------------------------------------------------------------- */
export function Outage() {
  const { id } = useParams<{ id: string }>()
  const [outage, setOutage] = useState<OutageEntity | null>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false)
  const [showEditUpdateModal, setShowEditUpdateModal] = useState(false)
  const [currentUpdate, setCurrentUpdate] = useState<{
    id: string
    description: string
    createdAt: Date
    updatedAt: Date
  } | null>(null)
  const queryClient = useQueryClient()

  const outageQuery = useQuery({
    queryKey: ['outage', id],
    queryFn: async () => {
      const response = await fetch(`/api/notifications/outages/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  useEffect(() => {
    if (outageQuery.data) {
      setOutage({
        id: outageQuery.data.id,
        title: outageQuery.data.title,
        description: outageQuery.data.description,
        status: outageQuery.data.status,
        impact: outageQuery.data.impact,
        severity: outageQuery.data.severity,
        type: outageQuery.data.type || OutageType.UNSCHEDULED, // Default to UNSCHEDULED if not provided
        start: new Date(outageQuery.data.start),
        end: outageQuery.data.end ? new Date(outageQuery.data.end) : undefined,
        createdAt: new Date(outageQuery.data.created_at),
        updatedAt: new Date(outageQuery.data.updated_at),
        updates: outageQuery.data.updates.map((update: any) => ({
          id: update.id,
          description: update.description,
          createdAt: new Date(update.created_at),
          updatedAt: new Date(update.updated_at),
        })),
      })
    }
  }, [outageQuery.data])

  // Handler for changing status using PATCH to /api/notifications/outages/:id
  const handleStatusChange = async (newStatus: OutageStatus) => {
    try {
      const response = await fetch(`/api/notifications/outages/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ status: newStatus }),
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      toast.success('Outage status updated successfully')
      queryClient.invalidateQueries({ queryKey: ['outage', id] })
    }
    catch (error: any) {
      toast.error(`Failed to update outage status: ${error.message}`)
    }
  }

  // Handler for saving edits from the edit modal using PATCH
  const handleEditSave = async (updatedData: Partial<OutageEntity>) => {
    try {
      const response = await fetch(`/api/notifications/outages/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(updatedData),
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      toast.success('Outage updated successfully')
      queryClient.invalidateQueries({ queryKey: ['outage', id] })
    }
    catch (error: any) {
      toast.error(`Failed to update outage: ${error.message}`)
    }
  }

  // Handler for adding a new update using POST to /api/notifications/outages/updates
  const handleAddUpdate = async (description: string) => {
    try {
      const payload = {
        outageId: outage?.id,
        description,
      }
      const response = await fetch(`/api/notifications/outages/updates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(payload),
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      toast.success('Update added successfully')
      queryClient.invalidateQueries({ queryKey: ['outage', id] })
    }
    catch (error: any) {
      toast.error(`Failed to add update: ${error.message}`)
    }
  }

  // Handler for saving edits for an individual update using PATCH
  const handleUpdateSave = async (updateId: string, updatedDescription: string) => {
    try {
      const response = await fetch(`/api/notifications/outages/updates/${updateId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ description: updatedDescription }),
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      toast.success('Update edited successfully')
      queryClient.invalidateQueries({ queryKey: ['outage', id] })
    }
    catch (error: any) {
      toast.error(`Failed to edit update: ${error.message}`)
    }
  }

  if (outageQuery.isLoading) {
    return <div>Loading outage details...</div>
  }

  if (outageQuery.isError) {
    return (
      <div>
        Error loading outage details:
        {' '}
        {(outageQuery.error as Error).message}
      </div>
    )
  }

  if (!outage) {
    return <div>Outage not found</div>
  }

  return (
    <>
      <Card className="my-4">
        <Card.Header>
          <div className="d-flex align-items-center">
            <Card.Title className="mb-0">{outage.title}</Card.Title>
            <div className="card-tools" style={{ marginLeft: 'auto' }}>
              <Button
                variant="outline-secondary"
                className="me-2 mr-2"
                onClick={() => setShowEditModal(true)}
              >
                <FontAwesomeIcon icon={faEdit} />
                {' '}
                Edit
              </Button>
              <ButtonGroup>
                <Button
                  variant={outage.status === OutageStatus.PLANNED ? 'primary' : 'outline-primary'}
                  onClick={() => handleStatusChange(OutageStatus.PLANNED)}
                >
                  <FontAwesomeIcon icon={faCalendar} />
                  {' '}
                  Planned
                </Button>
                <Button
                  variant={outage.status === OutageStatus.ACTIVE ? 'primary' : 'outline-primary'}
                  onClick={() => handleStatusChange(OutageStatus.ACTIVE)}
                >
                  <FontAwesomeIcon icon={faBolt} />
                  {' '}
                  Active
                </Button>
                <Button
                  variant={outage.status === OutageStatus.RESOLVED ? 'primary' : 'outline-primary'}
                  onClick={() => handleStatusChange(OutageStatus.RESOLVED)}
                >
                  <FontAwesomeIcon icon={faCheck} />
                  {' '}
                  Resolved
                </Button>
                <Button
                  variant={outage.status === OutageStatus.HIDDEN ? 'primary' : 'outline-primary'}
                  onClick={() => handleStatusChange(OutageStatus.HIDDEN)}
                >
                  <FontAwesomeIcon icon={faEyeSlash} />
                  {' '}
                  Hidden
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <dl className="row">
            <dt className="col-sm-3">Description</dt>
            <dd className="col-sm-9" style={{ whiteSpace: 'pre-wrap' }}>
              {outage.description}
            </dd>
            <dt className="col-sm-3">Status</dt>
            <dd className="col-sm-9">{outage.status}</dd>
            <dt className="col-sm-3">Type</dt>
            <dd className="col-sm-9">{outage.type}</dd>
            <dt className="col-sm-3">Impact</dt>
            <dd className="col-sm-9">{outage.impact}</dd>
            <dt className="col-sm-3">Severity</dt>
            <dd className="col-sm-9">{outage.severity}</dd>
            <dt className="col-sm-3">Start</dt>
            <dd className="col-sm-9">
              {new Date(outage.start).toLocaleString()}
            </dd>
            {outage.end && (
              <>
                <dt className="col-sm-3">End</dt>
                <dd className="col-sm-9">
                  {new Date(outage.end).toLocaleString()}
                  {' '}
                  {outage.status === OutageStatus.ACTIVE && '(estimate)'}
                </dd>
              </>
            )}
          </dl>
          <Card className="mt-4">
            <Card.Header>
              <Card.Title>Updates</Card.Title>
              <div className="card-tools">
                <Button variant="primary" onClick={() => setShowAddUpdateModal(true)}>
                  Add Update
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              {(!outage.updates || outage.updates.length === 0)
                ? (
                    <div>No updates available</div>
                  )
                : (
                    <ul className="list-group">
                      {outage.updates.map(update => (
                        <li className="list-group-item d-flex justify-content-between align-items-start" key={update.id}>
                          <div>
                            <p className="mb-1" style={{ whiteSpace: 'pre-wrap' }}>
                              {update.description}
                            </p>
                            <small className="text-muted">
                              {new Date(update.createdAt).toLocaleString('nl-NL')}
                              {update.createdAt.getTime() !== update.updatedAt.getTime() && ' (updated)'}
                            </small>
                          </div>
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={() => {
                              setCurrentUpdate({
                                ...update,
                                description: update.description ?? '',
                              })
                              setShowEditUpdateModal(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>

      {/* Edit Outage Modal */}
      <EditOutageModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        outage={outage}
        handleSave={handleEditSave}
      />

      {/* Add Update Modal */}
      <AddUpdateModal
        show={showAddUpdateModal}
        handleClose={() => setShowAddUpdateModal(false)}
        handleAddUpdate={handleAddUpdate}
      />

      {/* Edit Update Modal */}
      {currentUpdate && (
        <EditUpdateModal
          show={showEditUpdateModal}
          handleClose={() => {
            setShowEditUpdateModal(false)
            setCurrentUpdate(null)
          }}
          update={currentUpdate}
          handleSave={async (updatedDescription: string) => {
            await handleUpdateSave(currentUpdate.id, updatedDescription)
          }}
        />
      )}
    </>
  )
}
