// SecondaryNav.jsx
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './SecondaryNav.css'
import SecondaryNavContext from './SecondaryNavContext'

function SecondaryNav() {
  const { links } = useContext(SecondaryNavContext)
  const location = useLocation()

  if (!links.length) {
    return <div className="pt-2" />
  }

  return (
    <nav className="secondary-nav">
      <ul>
        {links.map(({ name, url }) => {
          const isActive = location.pathname === url
          return (
            <li key={url}>
              <Link to={url} className={isActive ? 'active' : ''}>
                {name}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default SecondaryNav
