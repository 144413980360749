import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Fireworks from '@fireworks-js/react'
import { addWindowClass, getScreenSize, removeWindowClass } from '../../util/helpers'
import LoadingScreen from '../../components/LoadingScreen'
import ErrorBoundary from '../../components/errors/ErrorBoundary'
import SecondaryNav from '../../components/SecondaryNav/SecondaryNav'
import Header from './header/Header'
import MenuSidebar from './menu-sidebar/MenuSidebar'
import Footer from './footer/Footer'
import './Main.css'

// import Snowfall from 'react-snowfall'; // Broken

function Main() {
  // const menuSidebarCollapsed = useSelector(
  //   (state: any) => state.ui.menuSidebarCollapsed
  // );
  // const controlSidebarCollapsed = useSelector(
  //   (state: any) => state.ui.controlSidebarCollapsed
  // );
  // const screenSize = useSelector((state: any) => state.ui.screenSize);
  const [isAppLoaded, setIsAppLoaded] = useState(true)
  const [isMenuSidebarCollapsed, setIsMenuSidebarCollapsed] = useState(false)
  const controlSidebarCollapsed = false

  const location = useLocation()
  const [oldLocation, setOldLocation] = useState(location)

  const [screenSize, setScreenSize] = useState(getScreenSize())

  useEffect(() => {
    removeWindowClass('register-page')
    removeWindowClass('login-page')
    removeWindowClass('hold-transition')

    addWindowClass('sidebar-mini')

    return () => {
      removeWindowClass('sidebar-mini')
    }
  }, [])

  // Collapse menu sidebar if location changes

  useEffect(() => {
    if (oldLocation.pathname !== location.pathname) {
      if (screenSize === 'sm' || screenSize === 'xs') {
        setIsMenuSidebarCollapsed(false)
      }
    }
    setOldLocation(location)
  }, [location])

  useEffect(() => {
    removeWindowClass('sidebar-closed')
    removeWindowClass('sidebar-collapse')
    removeWindowClass('sidebar-open')

    if (screenSize === 'lg' || screenSize === 'xl') {
      if (isMenuSidebarCollapsed) {
        addWindowClass('sidebar-collapse')
      }
      else {
        removeWindowClass('sidebar-collapse')
        addWindowClass('sidebar-closed')
      }
    }
    else {
      if (isMenuSidebarCollapsed) {
        addWindowClass('sidebar-open')
      }
      else {
        removeWindowClass('sidebar-open')
        addWindowClass('sidebar-closed')
        addWindowClass('sidebar-collapse')
      }
    }
  }, [screenSize, isMenuSidebarCollapsed])

  useEffect(() => {
    if (controlSidebarCollapsed) {
      removeWindowClass('control-sidebar-slide-open')
    }
    else {
      addWindowClass('control-sidebar-slide-open')
    }
  }, [screenSize, controlSidebarCollapsed])

  window.addEventListener('resize', () => {
    if (screenSize !== getScreenSize()) {
      setScreenSize(getScreenSize())
    }
  })

  // Only enable snow on the 25th or 26th of december
  // const snowEnabled = (
  //   new Date().getMonth() == 11 && (
  //     new Date().getDate() === 25 ||
  //     new Date().getDate() === 26
  //   )) ||
  //   false;

  // Only enable fireworks on new years eve
  const fireworksEnabled
    = (new Date().getMonth() == 11 && new Date().getDate() === 31)
    || (new Date().getMonth() == 0 && new Date().getDate() === 1)
    || false

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return <LoadingScreen loadingText="" />
    }

    return (
      <>
        {/* {snowEnabled && <Snowfall
          snowflakeCount={250}
          style={{
            zIndex: 9999
          }}
        />} */}

        {fireworksEnabled && (
          <Fireworks
            options={{
              rocketsPoint: {
                min: 0,
                max: 100,
              },
              particles: 200,
              intensity: 10,
            }}
            style={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              position: 'fixed',
              pointerEvents: 'none',
              zIndex: 9999,
            }}
          />
        )}

        <Header sidebar={{
          get: isMenuSidebarCollapsed,
          set: setIsMenuSidebarCollapsed,
        }}
        />

        <MenuSidebar sidebar={{
          get: isMenuSidebarCollapsed,
          set: setIsMenuSidebarCollapsed,
        }}
        />

        <div className="content-wrapper">
          <SecondaryNav />

          <section
            className="content"
          >
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </section>
        </div>

        <Footer />
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={() => setIsMenuSidebarCollapsed(prev => !prev)}
          // onKeyDown={() => {}}
        />
      </>
    )
  }, [isAppLoaded])

  return <div className="wrapper">{getAppTemplate()}</div>
}

export default Main
