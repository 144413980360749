export enum OutageStatus {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
  HIDDEN = 'HIDDEN',
  PLANNED = 'PLANNED',
}

export enum OutageType {
  SCHEDULED = 'SCHEDULED',
  UNSCHEDULED = 'UNSCHEDULED',
}

export enum OutageImpact {
  LOCAL = 'LOCAL',
  REGIONAL = 'REGIONAL',
  NATIONAL = 'NATIONAL',
  GLOBAL = 'GLOBAL',
}

export enum OutageSeverity {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export interface OutageEntity {
  id: number
  title: string
  description?: string
  status: OutageStatus
  impact: OutageImpact
  severity: OutageSeverity
  type: OutageType
  start: Date
  end?: Date
  createdAt: Date
  updatedAt: Date
  updates: OutageUpdateEntity[]
}

export interface OutageUpdateEntity {
  id: string
  description?: string
  createdAt: Date
  updatedAt: Date
}
