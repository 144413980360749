import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Aansluitingen from './Aansluitingen'
import { Aansluiting } from './Aansluitingen/[id]/Aansluiting'
import { BulkAansluitingToevoegen } from './Aansluitingen/BulkToevoegen'
import { AansluitingToevoegen } from './Aansluitingen/Toevoegen'
import { EVCOverview } from './EVC'
import { EVC } from './EVC/[id]/EVC'
import { EVCToevoegen } from './EVC/Toevoegen'
import { Interconnects } from './Interconnects'
import { InterconnectToevoegen } from './Interconnects/Toevoegen'
import { IPAdressen } from './IPAdressen'
import { DevicesModule } from './Devices/Devices.module'
import { Interconnect } from './Interconnects/[id]/Interconnect'

export function NetwerkbeheerModule() {
  return (
    <Routes>
      <Route path="/aansluitingen/toevoegen" element={<AansluitingToevoegen />} />
      <Route path="/aansluitingen/bulk_toevoegen" element={<BulkAansluitingToevoegen />} />
      <Route path="/aansluitingen/:id" element={<Aansluiting />} />
      <Route path="/aansluitingen" element={<Aansluitingen />} />

      <Route path="/interconnects/toevoegen" element={<InterconnectToevoegen />} />
      <Route path="/interconnects/:id" element={<Interconnect />} />
      <Route path="/interconnects" element={<Interconnects />} />

      <Route path="/evcs/toevoegen" element={<EVCToevoegen />} />
      <Route path="/evcs/:id" element={<EVC />} />
      <Route path="/evcs" element={<EVCOverview />} />

      <Route path="/apparaten/*" element={<DevicesModule />} />

      <Route path="/ipadressen" element={<IPAdressen />} />
    </Routes>
  )
}
