import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Form, Pagination, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import type { ApiResponse } from '../../../util/helpers'
import { selectTheme } from '../../../util/helpers'
import { getAccessToken } from '../../../services/auth.service'
import { DarkModeContext } from '../../../providers/DarkModeProvider'

interface DeviceApiResponse extends ApiResponse {
  result: any[]
}

interface DeviceModel {
  id: string
  deviceType: string
  deviceName: string
  vendor: string
  isPON: boolean
  isCPE: boolean
  defaultMonitoredPortIndex: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

interface selectTypeDeviceModel {
  value: DeviceModel
  label: string
}

export function NetworkDevices() {
  const [limit, setLimit] = React.useState(25)
  const [page, setPage] = React.useState(0)
  const [nameFilter, setNameFilter] = React.useState('')
  const [ipAddressFilter, setIpAddressFilter] = React.useState('')
  const [serialNumberFilter, setSerialNumberFilter] = React.useState('')
  const [modelFilter, setModelFilter] = React.useState<selectTypeDeviceModel | null>(null)

  const [modelOptions, setModelOptions] = React.useState<selectTypeDeviceModel[]>([])

  const [totalRows, setTotalRows] = React.useState(0)
  const [totalPages, setTotalPages] = React.useState(0)
  const [devices, setDevices] = React.useState<DeviceApiResponse['result']>([])

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const deviceModelQuery = useQuery({
    queryKey: ['deviceModel'],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devicemodels`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  React.useEffect(() => {
    if (deviceModelQuery.data) {
      setModelOptions(deviceModelQuery.data.map((model: DeviceModel) => {
        return {
          value: model,
          label: model.deviceName,
        }
      }))
    }
  }, [deviceModelQuery.data])

  const deviceQuery = useQuery({
    queryKey: ['devices', {
      limit,
      page,
      nameFilter,
      ipAddressFilter,
      serialNumberFilter,
      modelFilter,
    }],

    queryFn: async () => {
      let queryString = `/api/netwerk/devices?limit=${limit}&page=${page}`

      if (nameFilter)
        queryString += `&deviceName=${encodeURIComponent(nameFilter)}`
      if (ipAddressFilter)
        queryString += `&ipAddress=${encodeURIComponent(ipAddressFilter)}`
      if (serialNumberFilter)
        queryString += `&serialNumber=${encodeURIComponent(serialNumberFilter)}`
      if (modelFilter && modelFilter.value)
        queryString += `&deviceModelId=${modelFilter?.value.id}`

      const response = await fetch(queryString, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json() as DeviceApiResponse
    },
  })

  React.useEffect(() => {
    if (deviceQuery.data) {
      setDevices(deviceQuery.data.result)
      setTotalRows(deviceQuery.data.total_rows)
      setTotalPages(deviceQuery.data.total_pages)
    }
  }, [deviceQuery.data])

  return (
    <>
      <Card className="card-outline card-primary">
        <Card.Header>
          <Card.Title>
            Apparaten (
            {totalRows}
            )
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Model</th>
                <th>Serienummer</th>
                <th>IP Adres</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="text" className="form-control" placeholder="Naam" onChange={e => setNameFilter(e.target.value)} value={nameFilter} />
                </td>
                <td>
                  <Select
                    options={modelOptions}
                    onChange={e => setModelFilter(e)}
                    value={modelFilter}
                    theme={selectStyle}
                  />

                </td>
                <td>
                  <input type="text" className="form-control" placeholder="Serienummer" onChange={e => setSerialNumberFilter(e.target.value)} value={serialNumberFilter} />
                </td>
                <td>
                  <input type="text" className="form-control" placeholder="IP Adres" onChange={e => setIpAddressFilter(e.target.value)} value={ipAddressFilter} />
                </td>
                <td />
              </tr>
              {devices.map((device: any) => {
                return <DeviceRow key={device.id} {...device} />
              })}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          {/* Float left result selector, float right pagination */}
          <div className="float-left">
            <Form.Control as="select" value={limit} onChange={e => setLimit(Number.parseInt(e.target.value))}>
              <option value={25}>25 resultaten</option>
              <option value={50}>50 resultaten</option>
              <option value={100}>100 resultaten</option>
            </Form.Control>
          </div>
          <div className="float-right">
            <Pagination>
              <Pagination.Item onClick={() => setPage(0)} disabled={page === 0}>
                {`<<`}
              </Pagination.Item>
              <Pagination.Item onClick={() => setPage(page - 1)} disabled={page === 0}>
                {`<`}
              </Pagination.Item>
              {page > 1 && <Pagination.Item onClick={() => setPage(page - 2)}>{page - 1}</Pagination.Item>}
              {page > 0 && <Pagination.Item onClick={() => setPage(page - 1)}>{page}</Pagination.Item>}
              <Pagination.Item active activeLabel="">{page + 1}</Pagination.Item>
              {page < totalPages - 1 && <Pagination.Item onClick={() => setPage(page + 1)}>{page + 2}</Pagination.Item>}
              {page < totalPages - 2 && <Pagination.Item onClick={() => setPage(page + 2)}>{page + 3}</Pagination.Item>}
              <Pagination.Item onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}>
                {`>`}
              </Pagination.Item>
              <Pagination.Item onClick={() => setPage(totalPages - 1)} disabled={page === totalPages - 1}>
                {`>>`}
              </Pagination.Item>
            </Pagination>
          </div>

        </Card.Footer>
      </Card>

    </>
  )
}

function DeviceRow(device: any) {
  const navigate = useNavigate()

  const healthQuery = useQuery({
    queryKey: ['modem', 'health', device.id],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices/${device.id}/health`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
    refetchInterval: 1000 * 30,
  })

  return (
    <tr onDoubleClick={() => { navigate(`/netwerkbeheer/apparaten/${device.id}`) }}>
      <td>{device.deviceName}</td>
      <td>{device.deviceModel.deviceName}</td>
      <td>{device.serialNumber}</td>
      <td>{device.managementAddressAddress}</td>
      <td>
        {device.deviceModel.deviceType !== 'NOCPE' && (
          <>
            {
              healthQuery.isLoading && (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )
            }
            {/* Render the error message if the query failed */}
            {healthQuery.isError && (
              <span
                className="badge badge-warning"
                style={{
                  fontSize: '1rem',
                }}
              >
                KAN STATUS NIET OPHALEN
              </span>
            )}
            { /* Device health status flag */}
            {healthQuery.data && healthQuery.data.status === 'UP' && (
              <span
                className="badge badge-success"
                style={{
                  fontSize: '1rem',
                }}
              >
                ONLINE
              </span>
            )}
            {healthQuery.data && healthQuery.data.status === 'DOWN' && (
              <span
                className="badge badge-danger"
                style={{
                  fontSize: '1rem',
                }}
              >
                OFFLINE
              </span>
            )}
          </>
        )}
      </td>
    </tr>
  )
}
