// AddLinekeyModal.tsx
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getAccessToken } from '../../../../../../../services/auth.service'
import { selectTheme } from '../../../../../../../util/helpers'
import { DarkModeContext } from '../../../../../../../providers/DarkModeProvider'
import { LineKeyTypes, lineKeyTypeTranslations } from './lineKeyTypes'

export interface AvailableLine {
  index: number
  id: number
  extension: number
  label: string
}

interface Option {
  value: number
  label: string
}

interface AddLinekeyModalProps {
  show: boolean
  onHide: () => void
  toestelId: string
  macaddress: string
  /** The next available key index (calculated in the parent) */
  initialIndex: number
  /** List of available lines on the phone */
  availableLines: AvailableLine[]
  /** The voip customer object (to fetch accounts for BLF key type) */
  voipCustomer: { id: number } | null
  /** Callback triggered after a new line key is successfully added */
  onLinekeyAdded?: () => void
}

export const AddLinekeyModal: React.FC<AddLinekeyModalProps> = ({
  show,
  onHide,
  toestelId,
  macaddress,
  initialIndex,
  availableLines,
  voipCustomer,
  onLinekeyAdded,
}) => {
  // Form state
  const [index, setIndex] = useState<number>(initialIndex)
  const [line, setLine] = useState<Option | null>(null)
  const [type, setType] = useState<Option | null>(null)
  // For the "Value" field, we store both a text value and a selected option.
  const [value, setValue] = useState<string>('')
  const [valueOption, setValueOption] = useState<Option | null>(null)
  const [labelField, setLabelField] = useState<string>('')
  const [formError, setFormError] = useState<string | null>(null)

  const darkMode = useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  // Build options for line selection (for key type "Line")
  const lineOptions = useMemo(() => {
    return availableLines.map(l => ({
      value: l.index,
      label: `Line ${l.index} - ${l.label}`,
    }))
  }, [availableLines])

  // Build options for key type selection from the translation table.
  const lineKeyTypeOptions = useMemo(() => {
    return Object.entries(lineKeyTypeTranslations).map(([key, translation]) => ({
      value: Number(key),
      label: translation,
    }))
  }, [])

  // For key type "Line", build value options based on available lines' extensions.
  const lineKeyValueOptions = useMemo(() => {
    return availableLines.map(l => ({
      value: l.extension,
      label: `Ext ${l.extension} - ${l.label}`,
    }))
  }, [availableLines])

  // For key type "BLF", fetch the customer's accounts.
  const { data: accountsData, isLoading: accountsLoading, error: accountsError } = useQuery({
    queryKey: ['voipAccounts', voipCustomer?.id],
    enabled: voipCustomer !== null && type?.value === LineKeyTypes.BLF,
    queryFn: async () => {
      const response = await fetch(`/api/voip/klanten/${voipCustomer?.id}/accounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  // Memoize account options for BLF keys.
  const voipAccountOptions = useMemo(() => {
    if (!accountsData)
      return []
    // Assuming accountsData is an array of accounts with id, extension, and label.
    return accountsData.map((account: { id: number, extension: number, label: string }) => ({
      value: account.id,
      label: `${account.extension} - ${account.label}`,
    }))
  }, [accountsData])

  // Initialize form fields when the modal opens.
  useEffect(() => {
    if (show) {
      setIndex(initialIndex)
      if (lineOptions.length > 0)
        setLine(lineOptions[0])
      if (lineKeyTypeOptions.length > 0)
        setType(lineKeyTypeOptions[0])
      setValue('')
      setValueOption(null)
      setLabelField('')
      setFormError(null)
    }
  }, [show, initialIndex, lineOptions, lineKeyTypeOptions])

  // Compute the field for "Value" based on the selected key type.
  let renderValueField: JSX.Element | null = null
  if (type) {
    if (type.value === LineKeyTypes.Line) {
      renderValueField = (
        <Select
          theme={selectStyle}
          options={lineKeyValueOptions}
          value={valueOption}
          onChange={selectedOption => setValueOption(selectedOption as Option)}
          placeholder="Select a value"
        />
      )
    }
    else if (type.value === LineKeyTypes.BLF) {
      if (accountsLoading) {
        renderValueField = <div>Loading accounts...</div>
      }
      else if (accountsError) {
        renderValueField = <Alert variant="danger">Error loading accounts.</Alert>
      }
      else {
        renderValueField = (
          <Select
            theme={selectStyle}
            options={voipAccountOptions}
            value={valueOption}
            onChange={selectedOption => setValueOption(selectedOption as Option)}
            placeholder="Select an account"
          />
        )
      }
    }
    else {
      renderValueField = (
        <Form.Control
          type="text"
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder="Enter key value"
          required
        />
      )
    }
  }

  // Mutation for creating a new line key.
  const addLineKeyMutation = useMutation({
    mutationFn: async (data: {
      macaddress: string
      index: number
      line: number
      type: number
      value: string
      label: string
    }) => {
      const response = await fetch(`/api/voip/toestellen/${toestelId}/linekeys`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
      })
      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(errorText || 'Error adding line key')
      }
      return response.json()
    },
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormError(null)
    if (!line) {
      setFormError('Please select a line.')
      return
    }
    if (!type) {
      setFormError('Please select a key type.')
      return
    }
    let finalValue = ''
    if (type.value === LineKeyTypes.Line) {
      if (!valueOption) {
        setFormError('Please select a value from the dropdown.')
        return
      }
      finalValue = valueOption.value.toString()
    }
    else if (type.value === LineKeyTypes.BLF) {
      if (!valueOption) {
        setFormError('Please select an account from the dropdown.')
        return
      }
      finalValue = valueOption.value.toString()
    }
    else {
      if (!value) {
        setFormError('Please enter a value.')
        return
      }
      finalValue = value
    }
    addLineKeyMutation.mutate(
      {
        macaddress,
        index,
        line: line.value,
        type: type.value,
        value: finalValue,
        label: labelField,
      },
      {
        onSuccess: () => {
          onLinekeyAdded && onLinekeyAdded()
          onHide()
        },
        onError: (error: any) => {
          setFormError(error.message || 'Failed to add line key.')
        },
      },
    )
  }

  const isMutating = addLineKeyMutation.status === 'pending'

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Line Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <Alert variant="danger">{formError}</Alert>}
          {/* Display MAC address as read-only */}
          <Form.Group controlId="macAddress" className="mb-3">
            <Form.Label>MAC Address</Form.Label>
            <Form.Control type="text" value={macaddress} readOnly plaintext />
          </Form.Group>
          {/* Key Index field */}
          <Form.Group controlId="index" className="mb-3">
            <Form.Label>Key Index</Form.Label>
            <Form.Control
              type="number"
              value={index}
              onChange={e => setIndex(Number(e.target.value))}
              required
            />
          </Form.Group>
          {/* Line selection */}
          <Form.Group controlId="lineSelect" className="mb-3">
            <Form.Label>Line</Form.Label>
            <Select
              theme={selectStyle}
              options={lineOptions}
              value={line}
              onChange={selectedOption => setLine(selectedOption as Option)}
              placeholder="Select a line"
            />
          </Form.Group>
          {/* Key Type selection */}
          <Form.Group controlId="keyTypeSelect" className="mb-3">
            <Form.Label>Key Type</Form.Label>
            <Select
              theme={selectStyle}
              options={lineKeyTypeOptions}
              value={type}
              onChange={(selectedOption) => {
                setType(selectedOption as Option)
                // Reset the value field when key type changes.
                setValueOption(null)
                setValue('')
              }}
              placeholder="Select a key type"
            />
          </Form.Group>
          {/* Value input */}
          <Form.Group controlId="valueInput" className="mb-3">
            <Form.Label>Value</Form.Label>
            {renderValueField}
          </Form.Group>
          {/* Label input */}
          <Form.Group controlId="labelInput" className="mb-3">
            <Form.Label>Label</Form.Label>
            <Form.Control
              type="text"
              value={labelField}
              onChange={e => setLabelField(e.target.value)}
              placeholder="Enter key label"
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide} disabled={isMutating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isMutating}>
            {isMutating ? 'Adding...' : 'Add Line Key'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
