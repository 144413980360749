// EditLinekeyModal.tsx
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getAccessToken } from '../../../../../../../services/auth.service'
import { selectTheme } from '../../../../../../../util/helpers'
import { DarkModeContext } from '../../../../../../../providers/DarkModeProvider'
import { LineKeyTypes, lineKeyTypeTranslations } from './lineKeyTypes' // Adjust path as needed

export interface AvailableLine {
  index: number
  id: number
  extension: number
  label: string
}

interface Option {
  value: number
  label: string
}

interface EditLinekeyModalProps {
  show: boolean
  onHide: () => void
  toestelId: string
  macaddress: string
  /** The key index (immutable, used as identifier) */
  initialIndex: number
  /** The initial selected line (for the "line" property) */
  initialLine: number
  /** The initial key type (from the enum) */
  initialType: number
  /** The initial key value */
  initialValue: string
  /** The initial key label */
  initialLabel: string
  /** List of available lines on the phone */
  availableLines: AvailableLine[]
  /** The voip customer object (to fetch accounts for BLF key type) */
  voipCustomer: { id: number } | null
  /** Callback triggered after the key is successfully updated */
  onLinekeyEdited?: () => void
}

export const EditLinekeyModal: React.FC<EditLinekeyModalProps> = ({
  show,
  onHide,
  toestelId,
  macaddress,
  initialIndex,
  initialLine,
  initialType,
  initialValue,
  initialLabel,
  availableLines,
  voipCustomer,
  onLinekeyEdited,
}) => {
  // We display the key index as immutable (in the header and read-only field)
  // Form state for editable fields:
  const [line, setLine] = useState<Option | null>(null)
  const [type, setType] = useState<Option | null>(null)
  // For the "Value" field, we allow a text input or a dropdown:
  const [value, setValue] = useState<string>('')
  const [valueOption, setValueOption] = useState<Option | null>(null)
  const [labelField, setLabelField] = useState<string>('')
  const [formError, setFormError] = useState<string | null>(null)

  const darkMode = useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  // Build options for line selection.
  const lineOptions = useMemo(() => {
    return availableLines.map(l => ({
      value: l.index,
      label: `Line ${l.index} - ${l.label}`,
    }))
  }, [availableLines])

  // Build options for key type selection.
  const lineKeyTypeOptions = useMemo(() => {
    return Object.entries(lineKeyTypeTranslations).map(([key, translation]) => ({
      value: Number(key),
      label: translation,
    }))
  }, [])

  // For key type "Line", build value options from available lines' extensions.
  const lineKeyValueOptions = useMemo(() => {
    return availableLines.map(l => ({
      value: l.extension,
      label: `Ext ${l.extension} - ${l.label}`,
    }))
  }, [availableLines])

  // For key type "BLF", fetch the customer's accounts.
  const { data: accountsData, isLoading: accountsLoading, error: accountsError } = useQuery({
    queryKey: ['voipAccounts', voipCustomer?.id],
    enabled: voipCustomer !== null && type?.value === LineKeyTypes.BLF,
    queryFn: async () => {
      const response = await fetch(`/api/voip/klanten/${voipCustomer?.id}/accounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  // Memoize account options for BLF.
  const voipAccountOptions = useMemo(() => {
    if (!accountsData)
      return []
    return accountsData.map((account: { id: number, extension: number, label: string }) => ({
      value: account.id,
      label: `${account.extension} - ${account.label}`,
    }))
  }, [accountsData])

  // Initialize form fields when the modal opens.
  useEffect(() => {
    if (show) {
      const selectedLine = lineOptions.find(opt => opt.value === initialLine) || (lineOptions.length > 0 ? lineOptions[0] : null)
      setLine(selectedLine)
      const selectedType = lineKeyTypeOptions.find(opt => opt.value === initialType) || (lineKeyTypeOptions.length > 0 ? lineKeyTypeOptions[0] : null)
      setType(selectedType)
      if (selectedType) {
        if (selectedType.value === LineKeyTypes.Line) {
          const selectedValueOption = lineKeyValueOptions.find((opt: Option) => opt.value === Number(initialValue))
          setValueOption(selectedValueOption || null)
          setValue('')
        }
        else if (selectedType.value === LineKeyTypes.BLF) {
          const selectedValueOption = voipAccountOptions.find((opt: Option) => opt.value === Number(initialValue))
          setValueOption(selectedValueOption || null)
          setValue('')
        }
        else {
          setValue(initialValue)
          setValueOption(null)
        }
      }
      else {
        setValue(initialValue)
        setValueOption(null)
      }
      setLabelField(initialLabel)
      setFormError(null)
    }
  }, [show, initialLine, initialType, initialValue, initialLabel, lineOptions, lineKeyTypeOptions, lineKeyValueOptions, voipAccountOptions])

  // Mutation for updating the line key (using PATCH).
  const editLineKeyMutation = useMutation({
    mutationFn: async (data: {
      macaddress: string
      index: number
      line: number
      type: number
      value: string
      label: string
    }) => {
      const response = await fetch(`/api/voip/toestellen/${toestelId}/linekeys/${initialIndex}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
      })
      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(errorText || 'Error updating line key')
      }
      return response.json()
    },
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormError(null)
    if (!line) {
      setFormError('Please select a line.')
      return
    }
    if (!type) {
      setFormError('Please select a key type.')
      return
    }
    let finalValue = ''
    if (type.value === LineKeyTypes.Line) {
      if (!valueOption) {
        setFormError('Please select a value from the dropdown.')
        return
      }
      finalValue = valueOption.value.toString()
    }
    else if (type.value === LineKeyTypes.BLF) {
      if (!valueOption) {
        setFormError('Please select an account from the dropdown.')
        return
      }
      finalValue = valueOption.value.toString()
    }
    else {
      if (!value) {
        setFormError('Please enter a value.')
        return
      }
      finalValue = value
    }
    editLineKeyMutation.mutate(
      {
        macaddress,
        index: initialIndex, // key index is immutable
        line: line.value,
        type: type.value,
        value: finalValue,
        label: labelField,
      },
      {
        onSuccess: () => {
          onLinekeyEdited && onLinekeyEdited()
          onHide()
        },
        onError: (error: any) => {
          setFormError(error.message || 'Failed to update line key.')
        },
      },
    )
  }

  const isMutating = editLineKeyMutation.status === 'pending'

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Line Key
            {initialIndex}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <Alert variant="danger">{formError}</Alert>}
          {/* MAC address (read-only) */}
          <Form.Group controlId="macAddress" className="mb-3">
            <Form.Label>MAC Address</Form.Label>
            <Form.Control type="text" value={macaddress} readOnly plaintext />
          </Form.Group>
          {/* Display key index as read-only */}
          <Form.Group controlId="index" className="mb-3">
            <Form.Label>Key Index</Form.Label>
            <Form.Control type="number" value={initialIndex} readOnly plaintext />
          </Form.Group>
          {/* Line selection */}
          <Form.Group controlId="lineSelect" className="mb-3">
            <Form.Label>Line</Form.Label>
            <Select
              theme={selectStyle}
              options={lineOptions}
              value={line}
              onChange={selectedOption => setLine(selectedOption as Option)}
              placeholder="Select a line"
            />
          </Form.Group>
          {/* Key Type selection */}
          <Form.Group controlId="keyTypeSelect" className="mb-3">
            <Form.Label>Key Type</Form.Label>
            <Select
              theme={selectStyle}
              options={lineKeyTypeOptions}
              value={type}
              onChange={(selectedOption) => {
                setType(selectedOption as Option)
                // Reset value field when key type changes.
                setValueOption(null)
                setValue('')
              }}
              placeholder="Select a key type"
            />
          </Form.Group>
          {/* Value input */}
          <Form.Group controlId="valueInput" className="mb-3">
            <Form.Label>Value</Form.Label>
            {type && (type.value === LineKeyTypes.Line
              ? (
                  <Select
                    theme={selectStyle}
                    options={lineKeyValueOptions}
                    value={valueOption}
                    onChange={selectedOption => setValueOption(selectedOption as Option)}
                    placeholder="Select a value"
                  />
                )
              : type.value === LineKeyTypes.BLF
                ? (
                    accountsLoading
                      ? (
                          <div>Loading accounts...</div>
                        )
                      : accountsError
                        ? (
                            <Alert variant="danger">Error loading accounts.</Alert>
                          )
                        : (
                            <Select
                              theme={selectStyle}
                              options={voipAccountOptions}
                              value={valueOption}
                              onChange={selectedOption => setValueOption(selectedOption as Option)}
                              placeholder="Select an account"
                            />
                          )
                  )
                : (
                    <Form.Control
                      type="text"
                      value={value}
                      onChange={e => setValue(e.target.value)}
                      placeholder="Enter key value"
                      required
                    />
                  ))}
          </Form.Group>
          {/* Label input */}
          <Form.Group controlId="labelInput" className="mb-3">
            <Form.Label>Label</Form.Label>
            <Form.Control
              type="text"
              value={labelField}
              onChange={e => setLabelField(e.target.value)}
              placeholder="Enter key label"
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide} disabled={isMutating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isMutating}>
            {isMutating ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
